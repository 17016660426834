import { Observable, of } from 'rxjs';
import { Injectable } from "@angular/core";
import { InformationTextPage, _InformationTextPage } from "./models/Information.page.text.model";
import { AppConfiguration } from 'read-appsettings-json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorsTracker } from './models/ErrorsTracker.model';
import { catchError, tap } from 'rxjs/operators';
import { handleError } from './HttpErrorsHandler/HttpServiceErrorHandler.service';
import { toQueryString } from '../reusable-functions/reusable.functions';


@Injectable()
export class InformationPageTextService{

  url = AppConfiguration.Setting().urls;
  httpheader = {
    headers: new HttpHeaders({
      "content-type": "application/json",
    }),
  };


  informationTexts: InformationTextPage[] = [{
    id: 123456,
    landing_page_mainword: 'Just a text',
    landing_page_mainword_sub: 'this is real',
    findcoach_mainword: 'is this the coach',
    findcoach_mainword_sub: 'yes he is',
    becomecoach_mainword: 'do you like?',
    becomecoach_mainword_sub: 'i think so',
    event_mainword: 'let go!',
    event_mainword_sub: 'yeaa!',
    taketour_video_url:'https://imagetoshow.com',
    created_at: new Date(),
    updated_at: new Date(),
    active_text: true
  }]

  constructor(private _httpClientService: HttpClient){}



  _getInformationTexts(): Observable<_InformationTextPage[] | ErrorsTracker>{
    return this._httpClientService.get<_InformationTextPage[]>(`${this.url?.marketUrl}/Configuration/AllInformationPages`,this.httpheader)
    .pipe(
      tap(data => {
        console.log(data)
      }),
     // catchError(handleError)
    );
  } 


  _addInformationPage(infopage:  _InformationTextPage): Observable<number | ErrorsTracker> {
    return this._httpClientService
      .post<number>(
        `${this.url?.marketUrl}/Configuration/AddInformationPage?${toQueryString(infopage)}`,null,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
       // catchError(handleError)
      );
  }


  getInformationTexts(): Observable<InformationTextPage[]>{
    return of(this.informationTexts)
  }

  AddInformationText(data: InformationTextPage):Observable<boolean>{
    this.informationTexts.push(data);
    return of(true);
  }

  private UpdateInformationData(data: InformationTextPage): InformationTextPage{
    let findData =  this.informationTexts.find(x => x.id == data.id);
    findData.active_text = data.active_text;
    findData.becomecoach_mainword = data.becomecoach_mainword;
    findData.becomecoach_mainword_sub = data.becomecoach_mainword_sub;
    findData.created_at = data.created_at;
    findData.event_mainword = data.event_mainword;
    findData.event_mainword_sub = data.event_mainword_sub;
    findData.findcoach_mainword = data.findcoach_mainword;
    findData.findcoach_mainword_sub = data.findcoach_mainword_sub;
    findData.landing_page_mainword = data.landing_page_mainword;
    findData.landing_page_mainword_sub = data.landing_page_mainword_sub;
    findData.taketour_video_url = data.taketour_video_url;
    findData.updated_at = new Date();

    return findData;
  }

  UpdateInformationText(data):Observable<boolean>{
    let indexToFind = this.informationTexts.findIndex(x => x.id == data.id);
    let dataMapped = this.UpdateInformationData(data);
    this.informationTexts.splice(indexToFind,1,dataMapped);

    
    return of(true);
  }

  getInformationText(data: InformationTextPage):Observable<InformationTextPage>{
    return of(this.informationTexts.find(x => x.id == data.id))
  }

  deleteInformationText(data: InformationTextPage):Observable<boolean>{
    var dataIndex = this.informationTexts.findIndex(x => x.id == data.id);
    this.informationTexts.splice(dataIndex,1);

    return of(true);

  }

}