import { ErrorsTracker } from "./models/ErrorsTracker.model";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap, catchError } from "rxjs/operators";
import { handleError } from "./HttpErrorsHandler/HttpServiceErrorHandler.service";
import { SessionsModel, _SessionStatus } from "./models/sessions.model";
import { QueryStringModel } from "./models/query.string.model";
import { Externals } from "./GeneralConfig/externals.service";
import { toQueryString } from "../reusable-functions/reusable.functions";
import { AppSettingsService } from "./app.settings.service";
import { Urls } from "./models/app.settings.model";
import { AppConfiguration } from "read-appsettings-json";

@Injectable()
export class PrivateSessionsService {
  url = AppConfiguration.Setting().urls;
  userData: any =
    localStorage.getItem("bearerToken") &&
    JSON.parse(localStorage.getItem("bearerToken"));
  httpHeaders = {
    headers: new HttpHeaders({
      "content-type": "application/json",
      "x-auth-token": this.userData?.authToken,
    }),
  };
  constructor(private _httpClientService: HttpClient) {}
  getSessionStatus(): any {
    return this._httpClientService
      .get<_SessionStatus>(
        `${this.url?.marketUrl}/Configuration/AllSessionStatus`,
        this.httpHeaders
      )
      .pipe(
        tap((data) => {
          console.log(data);
        })
        // catchError(handleError)
      );
  }

  getSessions(queryParameters?: QueryStringModel): any {
    return this._httpClientService
      .get<SessionsModel>(
        `${this.url?.learnersUrl}/v1/session??type=group&perPage=${queryParameters.pageSize}&pageNo=${queryParameters.pageNumber}`,
        this.httpHeaders
      )
      .toPromise();
  }
  getSessionByDate(params: any): any {
    return this._httpClientService
      .get<SessionsModel>(
        `${this.url?.learnersUrl}/v1/session?fromDate=${params.fromDate}`,
        this.httpHeaders
      )
      .toPromise();
  }
  getSessionByType(params: any) {
    return this._httpClientService
      .get<SessionsModel>(
        `${this.url?.learnersUrl}/v1/session?type=${params.sessionType}`,
        this.httpHeaders
      )
      .toPromise();
  }

  getSessionByTypeAndDate(params: any) {
    if (params.startDate && params.endDate && params.currency) {
      return this._httpClientService
        .get<any>(
          `${this.url?.learnersUrl}/v1/transactions?startDate=${params.startDate}&endDate=${params.endDate}&currency=${params.currency}`,
          this.httpHeaders
        )
        .toPromise();
    } else {
      return this._httpClientService
        .get<any>(
          `${this.url?.learnersUrl}/v1/transactions?startDate=${params.startDate}&endDate=${params.endDate}`,
          this.httpHeaders
        )
        .toPromise();
    }
  }

  getGroupSessions(queryParameters?: QueryStringModel): any {
    return this._httpClientService
      .get<SessionsModel>(
        `${this.url?.learnersUrl}/v1/session?type=group&perPage=${queryParameters.pageSize}&pageNo=${queryParameters.pageNumber}`,
        this.httpHeaders
      )
      .toPromise();
  }

  getSingleSessions(id?: any): any {
    return this._httpClientService
      .get(
        `${this.url?.learnersUrl}/v1/session/${id}/attendance`,
        this.httpHeaders
      )
      .toPromise();
  }

  getSessionTranxStat(id: any) {
    return this._httpClientService
      .get(`${this.url?.learnersUrl}/v1/tranx/${id}`, this.httpHeaders)
      .toPromise();
  }
  getPayoutRecord(query: any) {
    return this._httpClientService
      .get(
        `${this.url?.learnersUrl}/v1/payout?perPage=${query.pageSize}&pageNo=${query.pageNumber}`,
        this.httpHeaders
      )
      .toPromise();
  }
  filterPayoutRecord(query) {
    if (query.startDate && query.endDate && query.currency) {
      return this._httpClientService
        .get(
          `${this.url?.learnersUrl}/v1/payout?startDate=${query.startDate}&endDate=${query.endDate}&currency=${query.currency}`,
          this.httpHeaders
        )
        .toPromise();
    }
  }

  getBillingsSessions(queryParameters: any): any {
    return this._httpClientService
      .get(
        `${this.url?.learnersUrl}/v1/transactions?perPage=${queryParameters.pageSize}&pageNo=${queryParameters.pageNumber}`,
        this.httpHeaders
      )
      .toPromise();
  }

  getAllSessions(queryParameters: any): any {
    return this._httpClientService
      .get(
        `${this.url?.learnersUrl}/v1/session?perPage=${queryParameters.pageSize}&pageNo=${queryParameters.pageNumber}`,
        this.httpHeaders
      )
      .toPromise();
  }
}
