export class ServiceDomain{
  id:number;
  service_domain_name: string;
  description: string;
  service_domain_category_id: number;
  created_at: Date;
  updated_at: Date;
}



export class _ServiceDomain{
  id:number;
  name: string;
  description: string;
  serviceDomainCategoryId: number;
  serviceDomainCategoryName: string
}

export class _ServiceDomain_ServiceDomainCategory{
  id:number;
  serviceDomainCategoryId:number;
  name: string;
  description: string;
  service_domain_category_name: string;
  serviceDomainCategoryName: string
}
export class ServiceDomainVm{
  id:number;
  service_domain_name: string;
  description: string;
  service_domain_category_id: number;
  service_domain_category_name: string;
  created_at: Date;
  updated_at: Date;
}