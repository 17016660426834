import { Router } from '@angular/router';
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { ErrorResponse, ErrorsTracker } from '../models/ErrorsTracker.model';
//ErrorResponse

export function handleError(error: HttpErrorResponse): Observable<ErrorsTracker>{
  let _errorToBeReturned:ErrorsTracker;
  //let router:Router;
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    switch (error.status) {
      case 401:{  //login
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'bad url request');

        //this.router.navigateByUrl("/login");
        console.log(`redirect to login ${_errorToBeReturned}`);
        
        return throwError(_errorToBeReturned);
      }    
      case 403:{     //forbidden
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'forbidden  request');
        //this.router.navigateByUrl("/login");
        console.log(`redirect to login ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 404:{ //url cannot be found
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'url cannot be found');
        console.log(`url cannot be found  ${_errorToBeReturned.friendlyMessage}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 400:{ // internal server error
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'internal server error');
        console.log(`internal server error  ${_errorToBeReturned.message}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 500:{ // internal server error
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'internal server error');
        console.log(`internal server error  ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      default:{
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'custom error from the server');
        console.log(`custom server error  ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
    }
  }
  // Return an observable with a user-facing error message.
  return throwError(
    'Something bad happened; please try again later.');
}


export function handleErrorErrorResponse(error: HttpErrorResponse): Observable<ErrorResponse>{
  let _errorToBeReturned:ErrorResponse;
  //let router:Router;
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    switch (error.status) {
      case 401:{  //login
        _errorToBeReturned = 
        new ErrorResponse(error.error,error.message,error.name,error.ok,error.status,error.url);

        //this.router.navigateByUrl("/login");
        console.log(`redirect to login ${_errorToBeReturned}`);
        
        return throwError(_errorToBeReturned);
      }    
      case 403:{     //forbidden
        _errorToBeReturned = 
        new ErrorResponse(error.error,error.message,error.name,error.ok,error.status,error.url);
        //this.router.navigateByUrl("/login");
        console.log(`redirect to login ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 404:{ //url cannot be found
        _errorToBeReturned = 
        new ErrorResponse(error.error,error.message,error.name,error.ok,error.status,error.url);
        console.log(`url cannot be found  ${_errorToBeReturned.error.message}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 400:{ // internal server error
        _errorToBeReturned = 
        new ErrorResponse(error.error,error.message,error.name,error.ok,error.status,error.url);
        console.log(`internal server error  ${_errorToBeReturned.message}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 500:{ // internal server error
        _errorToBeReturned = 
        new ErrorResponse(error.error,error.message,error.name,error.ok,error.status,error.url);
        console.log(`internal server error  ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      default:{
        new ErrorResponse(error.error,error.message,error.name,error.ok,error.status,error.url);
        new ErrorsTracker(error.status,error.error,'custom error from the server');
        console.log(`custom server error  ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
    }
  }
  // Return an observable with a user-facing error message.
  return throwError(
    'Something bad happened; please try again later.');
}
export function handleErrorForRegistration(error: HttpErrorResponse): Observable<ErrorsTracker>{
  let _errorToBeReturned:ErrorsTracker;
  //let router:Router;
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    switch (error.status) {
      case 401:{  //login
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'bad url request');

        //this.router.navigateByUrl("/login");
        console.log(`redirect to login ${_errorToBeReturned}`);
        
        return throwError(_errorToBeReturned);
      }    
      case 403:{     //forbidden
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'forbidden  request');
        //this.router.navigateByUrl("/login");
        console.log(`redirect to login ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 404:{ //url cannot be found
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'url cannot be found');
        console.log(`url cannot be found  ${_errorToBeReturned.friendlyMessage}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 400:{ // internal server error
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'internal server error');
        console.log(`internal server error  ${_errorToBeReturned.message}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 500:{ // internal server error
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'internal server error');
        console.log(`internal server error  ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      default:{
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'custom error from the server');
        console.log(`custom server error  ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
    }
  }
  // Return an observable with a user-facing error message.
  return throwError(
    'Something bad happened; please try again later.');
}
export function handleErrorAny(error: HttpErrorResponse){
  let _errorToBeReturned:ErrorsTracker;
  //let router:Router;
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    switch (error.status) {
      case 401:{  //login
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'bad url request');

        //this.router.navigateByUrl("/login");
        console.log(`redirect to login ${_errorToBeReturned}`);
        
        return throwError(_errorToBeReturned);
      }    
      case 403:{     //forbidden
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'forbidden  request');
        //this.router.navigateByUrl("/login");
        console.log(`redirect to login ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 400:{ // internal server error
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'internal server error');
        console.log(`internal server error  ${_errorToBeReturned.message}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 500:{ // internal server error
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'internal server error');
        console.log(`internal server error  ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      case 400:{ // internal server error
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'internal server error');
        console.log(`internal server error  ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
      default:{
        _errorToBeReturned = 
        new ErrorsTracker(error.status,error.error,'custom error from the server');
        console.log(`custom server error  ${_errorToBeReturned}`);
        //handled = true;
        return throwError(_errorToBeReturned);
      }
    }
  }
  // Return an observable with a user-facing error message.
  return throwError(
    'Something bad happened; please try again later.');
}