

export class InformationTextPage{
   id:number;
   landing_page_mainword: string;
   landing_page_mainword_sub: string;
   findcoach_mainword: string;
   findcoach_mainword_sub:string;
   becomecoach_mainword: string;
   becomecoach_mainword_sub: string;
   event_mainword: string;
   event_mainword_sub: string;
   taketour_video_url: string;
   active_text: boolean;
   created_at: Date;
   updated_at: Date;

}


export class _InformationTextPage{
   
   id: number;
   landingPageMainword: string;
   landingPageMainwordSub: string;
   findcoachMainword: string;
   findcoachMainwordSub: string;
   becomecoachMainword: string;
   becomecoachMainwordSub: string;
   eventMainword: string;
   eventMainwordSub: string;
   taketourVideoUrl:string;
   activeText: boolean;
    
}