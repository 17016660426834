import { PricingPlan } from 'src/app/core/services/models/Pricing.plan.model';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingPlanService } from 'src/app/core/services/pricing.plan.service';
import { PricingPlanDescription } from 'src/app/core/services/models/pricing.plan.description.model';

@Component({
  selector: 'app-pricing-description-edit',
  templateUrl: 'pricing.description.edit.modal.html',
})
export class PricingDescriptionModalEdit implements OnInit {
  focus;
  form: FormGroup;
  pricingPlanList: PricingPlan[] = [];
  pricingPlanDescription: PricingPlanDescription;
  pricingDescription_id: number;


  @Input() public description: PricingPlanDescription;

  constructor(
    public _modal: NgbActiveModal,
    private _pricingPlans: PricingPlanService
  ) {
    this._pricingPlans.getPricingPlans().subscribe((result: PricingPlan[]) => {
      this.pricingPlanList = result;
    });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(
        { value: this.description.id, disabled: false },
        [Validators.required]
      ),
      pricing_plan_id: new FormControl(
        { value: this.description.pricing_plan_id, disabled: false },
        [Validators.required]
      ),
      description_by_eachline: new FormControl(
        {
          value: this.description.description_by_eachline,
          disabled: false,
        },
        [Validators.required]
      ),
    });
  }

  get  id(){
    return this.form.get('id')
  }
  get pricing_plan_id(){
    return this.form.get('pricing_plan_id');
  }
 
  get description_by_eachline(){
   return this.form.get('description_by_eachline');
 }


  getPricingPlan($event) {
    console.log('pricing selected' , $event);
    this.pricingDescription_id = $event;
  }

  dismiss() {
    this._modal.dismiss();
  }

  onSubmit() {
    this.pricingPlanDescription = new PricingPlanDescription();
    this.pricingPlanDescription.id = this.form.value.id;
    this.pricingPlanDescription.description_by_eachline = this.form.value.description_by_eachline;
    this.pricingPlanDescription.pricing_plan_id = this.form.value.pricing_plan_id;
    this.pricingPlanDescription.created_at =this.description.created_at;
    this.pricingPlanDescription.updated_at = new Date();
  
    console.log('here is the result -->', this.pricingPlanDescription);
    this._modal.close(this.pricingPlanDescription)
  }
}
