import { Router } from "@angular/router";

import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { AppUser, UserLogin, UserRegistration } from "./models/AppUser";
import { AppUserAuth, LOGIN_MOCKS } from "./models/AppUserAuth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfiguration } from "read-appsettings-json";
import { catchError, tap } from "rxjs/operators";
import jwt_decode from "jwt-decode";
import {
  handleError,
  handleErrorErrorResponse,
} from "./HttpErrorsHandler/HttpServiceErrorHandler.service";
import { ErrorResponse, ErrorsTracker } from "./models/ErrorsTracker.model";
/**
 * Developer : Tobi Adeogun
 * Purpose : This service is responsible for Authenticating the user on a mockup level
 */

@Injectable()
export class AuthService {
  // initialize AppAuthUser
  securityObject: AppUserAuth = new AppUserAuth();

  url = AppConfiguration.Setting().urls;

  httpheader = {
    headers: new HttpHeaders({
      "content-type": "application/json",
    }),
  };
  constructor(private _route: Router, private _httpService: HttpClient) {}

  login(entity: AppUser): Observable<AppUserAuth> {
    this.resetSecurityObject();

    /**
     * Use object assign to update the current object
     * NOTE: Don't crete a new AppAuthUser object because that destroy all references to object
     */
    Object.assign(
      this.securityObject,
      LOGIN_MOCKS.find(
        (user) => user.userName.toLowerCase() === entity.userName.toLowerCase()
      )
    );

    if (this.securityObject.userName !== "") {
      // store token locally
      const expDate = new Date().getTime() + 1 * 60 * 1000;
      console.log(this.securityObject.bearerToken, "token");
      // localStorage.setItem("bearerToken", this.securityObject.bearerToken);
      // this.autoLogout(expDate);
    }

    // return an observable of AppUserAuth
    return of<AppUserAuth>(this.securityObject);
  }
  autoLogout(expDate: number) {
    setTimeout(() => {
      this.logout();
    }, expDate);
  }
  logout(): void {
    this.resetSecurityObject();
  }

  resetSecurityObject(): void {
    localStorage.removeItem("bearerToken");
  }

  //UserRegistration https://localhost:44310
  registerAdminUser(data: any): Observable<UserRegistration | ErrorResponse> {
    return this._httpService
      .post<UserRegistration>(
        `${this.url.authUrl}/register/admin`,
        data,
        this.httpheader
      )
      .pipe(
        tap((data) => console.log(data)),
        catchError(handleErrorErrorResponse)
      );
  }
  isTokenExpired(token: string) {
    const decoded: any = jwt_decode(token);
    const currentTime = new Date().getTime();
    if (decoded?.exp * 1000 < currentTime) {
      return true;
    } else {
      return false;
    }
  }
  logInUser(data: any): Observable<UserLogin | ErrorResponse> {
    return this._httpService
      .post<UserLogin>(`${this.url.authUrl}/Auth/login`, data, this.httpheader)
      .pipe(
        tap((data) => console.log(data)),
        catchError(handleErrorErrorResponse)
      );
  }
}
