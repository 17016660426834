import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/core/services/auth.service";

var misc: any = {
  sidebar_mini_active: true,
};
/**
 * This class is responsible for side bar navigation menu links, icons, routes and collapsible
 */
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
//Menu Items with routes
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboards",
    title: "Dashboards",
    type: "sub",
    icontype: "ni-shop text-primary",
    isCollapsed: true,
    children: [
      // { path: "dashboard", title: "Dashboard", type: "link" },
      { path: "alternative", title: "Alternative", type: "link" },
    ],
  },
  {
    path: "/billings",
    title: "Transactions",
    type: "link",
    icontype: "ni-shop text-primary",
  },
  {
    path: "/sessions",
    title: "Sessions",
    type: "sub",
    icontype: "ni-active-40 text-orange",
    collapse: "examples",
    isCollapsed: true,
    children: [
      { path: "group-session", title: "Group session", type: "link" },
      { path: "private-session", title: "One on one session", type: "link" },
    ],
  },
  // {
  //   path: "/events",
  //   title: "Events",
  //   type: "sub",
  //   icontype: "ni-calendar-grid-58 text-info",
  //   collapse: "components",
  //   isCollapsed: true,
  //   children: [
  //      { path: "events-all", title: "Events", type: "link" },

  //   ]
  // },
  {
    path: "",
    title: "Subscribers",
    type: "sub",
    icontype: "fas fa-user-friends text-pink",
    collapse: "forms",
    isCollapsed: true,
    children: [{ path: "subscribers", title: "Subscribers", type: "link" }],
  },
  {
    path: "/experts",
    title: "Experts",
    // type: "sub",
    type: "link",
    icontype: "fas fa-graduation-cap text-default",
    collapse: "tables",
    isCollapsed: true,
    // children: [
    //   // { path: "experts", title: "Experts", type: "link" },
    //   // { path: "approved", title: "approved Coaches", type: "link" },
    //   // { path: "notapproved", title: "up-approved coaches", type: "link" },
    // ]
  },
  {
    path: "/payouts",
    title: "Payout",
    type: "link",
    icontype: "fas fa-users-cog text-primary",
  },

  {
    path: "/management",
    title: "Management",
    type: "sub",
    icontype: "fas fa-users-cog text-primary",
    collapse: "maps",
    isCollapsed: true,
    children: [
      { path: "invitations", title: "New Intivations", type: "link" },
      {
        path: "flutterwave",
        title: "flutterwave payout records",
        type: "link",
      },
      {
        path: "stripe",
        title: "stripe payout records",
        type: "link",
      },
      {
        path: "converted-exchange",
        title: "converted exchange payout records",
        type: "link",
      },
      {
        path: "approved-intitations",
        title: "Approved intivations",
        type: "link",
      },
      {
        path: "approved-intitations",
        title: "Approved intivations",
        type: "link",
      },
      { path: "registeradminuser", title: "Add user", type: "link" },
      { path: "adminUsers", title: "Resgistered users", type: "link" },
    ],
  },
  {
    path: "/settings",
    title: "General Settings",
    type: "sub",
    icontype: "fas fa-toolbox text-primary",
    collapse: "tables",
    isCollapsed: true,
    children: [
      { path: "country", title: "Country", type: "link" },
      { path: "state", title: "State", type: "link" },
      { path: "city", title: "City", type: "link" },
      { path: "designation", title: "Designation", type: "link" },
      { path: "organization", title: "Organization", type: "link" },
      { path: "information-text", title: "Information text", type: "link" },
      // { path: "pricing-plan", title: "Pricing plan", type: "link" },
      // { path: "pricing-description", title: "Pricing description", type: "link" },
      {
        path: "service-domain-category",
        title: "Service domain category",
        type: "link",
      },
      { path: "domain-service-name", title: "Service domain", type: "link" },
      { path: "user-settings", title: "User Settings", type: "link" },
    ],
  },
];

/**
 * 
 * Information text
Pricing Plan
Pricing Session
Pricing Description
Service Category e.g Healthcare
Sevice Category Domain
 */
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private securityService: AuthService
  ) {}

  get isAuthenticated() {
    let gottenProfile = JSON.stringify(localStorage.getItem("bearerToken"));
    return gottenProfile ? true : false;
  }
  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }

  logUserOut() {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.securityService.logout();
      this.router.navigate(["/auth/login"]);
      this.spinner.hide();
    }, 2000);
  }
  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }
  minimizeSidebar() {
    const sidenavToggler =
      document.getElementsByClassName("sidenav-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }
}
