import { Observable, of } from 'rxjs';
import { Injectable } from "@angular/core";
import { PricingPlan, _PricingPlan } from "./models/Pricing.plan.model";
import { AppConfiguration } from 'read-appsettings-json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { handleError } from './HttpErrorsHandler/HttpServiceErrorHandler.service';
import { ErrorsTracker } from './models/ErrorsTracker.model';


@Injectable()
export class PricingPlanService{

  url = AppConfiguration.Setting().urls;
  httpHeaders = {
    headers: new HttpHeaders({
      'content-type':'application/json'
    })
  }
  private pricingPlanServices: PricingPlan[]=[{
    id:567783,
    plan_name: 'On-demand',
    plan_brief_description: 'Suitable for on-going or stalled goals',
    number_of_session: 1,
    number_of_session_permonth: 1,
    created_at: new Date(),
    updated_at: new Date()
  },
  {
    id:567727,
    plan_name: 'Basic',
    plan_brief_description: 'Suitable for short-term goals',
    number_of_session: 9,
    number_of_session_permonth: 3,
    created_at: new Date(),
    updated_at: new Date()
  },

  {
    id:567738,
    plan_name: 'Standard',
    plan_brief_description: 'Suitable for short to medium-term goals',
    number_of_session: 18,
    number_of_session_permonth: 3,
    created_at: new Date(),
    updated_at: new Date()
  },

  {
    id:567718,
    plan_name: 'Ultimate',
    plan_brief_description: 'Suitable for medium to long-term goals',
    number_of_session: 36,
    number_of_session_permonth: 3,
    created_at: new Date(),
    updated_at: new Date()
  }
]

  constructor(private _httpClientService: HttpClient){}


  _getPricingPlans():Observable<_PricingPlan[] | ErrorsTracker>{
    return this._httpClientService.get<_PricingPlan[]>(`${this.url?.marketUrl}/Configuration/AllPricingPlans`,this.httpHeaders)
    .pipe(
      tap(data => {
        console.log(data)
      }),
     // catchError(handleError)
    );
  }

  PricingPlanAlreadyExist(data:PricingPlan):Observable<boolean> {
    let result = this._pricingPlanServices.find(x => x.plan_name.toLowerCase() == data.plan_name.toLowerCase());

    if(result) return of(true);

    return of(false);
  }
  get _pricingPlanServices():PricingPlan[]{
     return this. pricingPlanServices;
  }

  private MapPricingPlans(data: PricingPlan): PricingPlan{
    let findData =  this.pricingPlanServices.find(x => x.id == data.id);
    findData.plan_name = data.plan_name;
    findData.plan_brief_description = data.plan_brief_description;
    findData.number_of_session = data.number_of_session;
    findData.created_at = data.created_at;
    findData.number_of_session_permonth = data.number_of_session_permonth;
    findData.updated_at = new Date();

    return findData;
  }

  UpdatePricingPlan(data):Observable<boolean>{
    let indexToFind = this.pricingPlanServices.findIndex(x => x.id == data.id);
    let dataMapped = this.MapPricingPlans(data);
    this.pricingPlanServices.splice(indexToFind,1,dataMapped);   
    return of(true);
  }
  getPricingPlans(): Observable<PricingPlan[]>{
    return of(this.pricingPlanServices)
  }

  AddPricingPlan(data: PricingPlan):Observable<boolean>{
    this.pricingPlanServices.push(data);
    return of(true);
  }

  getPricingPlan(data: PricingPlan):Observable<PricingPlan>{
    return of(this.pricingPlanServices.find(x => x.id == data.id))
  }

  deletePricingPlan(data: PricingPlan):Observable<boolean>{
    var dataIndex = this.pricingPlanServices.findIndex(x => x.id == data.id);
    this.pricingPlanServices.splice(dataIndex,1);

    return of(true);

  }
}