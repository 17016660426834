import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from "@angular/core";
import { City, _City_State_Country } from 'src/app/pages/settings/components/city/model/city.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-edit-city-modal',
  templateUrl: 'city.edit.modal.html'
})
export class EditCityModal implements OnInit{


  // moment time zone initialization

    public tzNames: string[];

    public userTz: string;

    public selectedTz: string;

    public selectedLocale: string;

   @Input() public CityToEdit: _City_State_Country;
   form: FormGroup;
   focus:boolean;
   constructor(public _modalActive: NgbActiveModal){}

   ngOnInit(): void{
    console.log('Addnnn city to edit -->',this.CityToEdit)

    this.userTz = moment.tz.guess();
    this.tzNames = moment.tz.names();

    this.form = new FormGroup({

      country_name:new FormControl({value :this.CityToEdit.country_name,disabled:true}, [
        Validators.required,
        Validators.minLength(3),
      ]),
      state_name:new FormControl({value :this.CityToEdit.state_name,disabled:true}, [
        Validators.required,
        Validators.minLength(3),
      ]),
      city_name: new FormControl({value : this.CityToEdit.city_name,disabled:false},[Validators.required,Validators.minLength(3)]),
      timezone: new FormControl({value :'',disabled:false}, [
        Validators.required,
        Validators.minLength(3),
      ]),


    })
   }



  timeZoneChanged(timeZone: string){
    console.log(timeZone);
    this.selectedTz = timeZone;

    //this.updateTime(timeZone);
  }

  get country_name(){
    return this.form.get('country_name');
  }

  get state_name(){
    return this.form.get('state_name');
  }

   get city_name(){
     return this.form.get('city_name');
   }

   get timezone() {
    return this.form.get('timezone');
  }


   onSubmit(){
     this.dismiss();
     alert('Update city endpoint is yet to be implemented!')
    //  this.CityToEdit.city_name = this.city_name.value;
    //  this.CityToEdit.timezone = this.selectedTz;
    //  this.CityToEdit.updated_at = new Date();
    //  this._modalActive.close(this.CityToEdit);
   }

   dismiss(){
     this._modalActive.dismiss();
   }
}