export class Designation{
  id: number;
  designation_name: string;
  created_at: Date;
  updated_at: Date;
}

export class _Designation{
  id: number;
  name: string;
 
}