import { PricingPlanDescription } from './../../../core/services/models/pricing.plan.description.model';
import { PricingPlan } from 'src/app/core/services/models/Pricing.plan.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Component, OnInit} from '@angular/core'
import { PricingPlanService } from 'src/app/core/services/pricing.plan.service';


@Component({
 selector: 'app-pricing-description-modal',
 templateUrl: 'pricing.descriptions.modal.html'
})
export class PricingDescriptionModal implements OnInit{

  focus;
  form: FormGroup;
  pricingPlanList: PricingPlan[] = [];
  pricingPlanDescription: PricingPlanDescription;
  pricingDescription_id: number;

 
  constructor(public _modal: NgbActiveModal,
    private _pricingPlans: PricingPlanService){
      this._pricingPlans.getPricingPlans().subscribe((result:PricingPlan[]) => {
        this.pricingPlanList = result;
      })
  }

 ngOnInit(): void {
   this.form = new FormGroup({
     'pricing_plan_id': new FormControl('',[Validators.required]),
     'description_by_eachline': new FormControl('',[Validators.required]),
   })
 }

 get pricing_plan_id(){
   return this.form.get('pricing_plan_id');
 }

 get description_by_eachline(){
  return this.form.get('description_by_eachline');
}

 onSubmit(){

  this.pricingPlanDescription = new PricingPlanDescription();
  this.pricingPlanDescription.id = this.generateRandomSixDigitNumber();
  this.pricingPlanDescription.description_by_eachline = this.form.value.description_by_eachline;
  this.pricingPlanDescription.pricing_plan_id = this.pricingDescription_id;
  this.pricingPlanDescription.created_at = new Date();
  this.pricingPlanDescription.updated_at = new Date();

  console.log('here is the result -->', this.pricingPlanDescription);
  this._modal.close(this.pricingPlanDescription)

 }

 getPricingPlan($event){
  console.log('pricing selected' , $event);
  this.pricingDescription_id = $event;

 

 }

  dismiss(){
    this._modal.dismiss();
  }

  

  private generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }
}