import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { _SessionStatus } from "./models/sessions.model";
import { AppConfiguration } from "read-appsettings-json";
import { QueryStringModel } from "./models/query.string.model";

@Injectable()
export class PayoutService {
  url = AppConfiguration.Setting().urls;
  userData: any =
    localStorage.getItem("bearerToken") &&
    JSON.parse(localStorage.getItem("bearerToken"));
  httpHeaders = {
    headers: new HttpHeaders({
      "content-type": "application/json",
      "x-auth-token": this.userData?.authToken,
    }),
  };
  constructor(private _httpClientService: HttpClient) {}

  getPayouts(
    queryParameters?: QueryStringModel,
    additionalParams?: any,
    expertId?: any
  ): any {
    return this._httpClientService
      .get(
        `${this.url?.learnersUrl}/v1/payout-history/${expertId}/admin?pageNo=${queryParameters.pageNumber}&perPage=${queryParameters.pageSize}&currency=NGN`,
        this.httpHeaders
      )
      .toPromise();
    // &paymentProvider=${additionalParams.provider}&currency=${additionalParams.currency}
    // return this._httpClientService.get(`${this.url?.learnersUrl}/v1/payout`, this.httpHeaders).toPromise()
  }
  getStat() {
    return this._httpClientService
      .get(`${this.url?.learnersUrl}/v1/admin/stat`, this.httpHeaders)
      .toPromise();
  }
  getConvPayouts(
    queryParameters?: QueryStringModel,
    additionalParams?: any,
    expertId?: any
  ): any {
    return this._httpClientService
      .get(
        `${this.url?.learnersUrl}/v1/payout-history/${expertId}/admin?pageNo=${queryParameters.pageNumber}&perPage=${queryParameters.pageSize}`,
        this.httpHeaders
      )
      .toPromise();
    // &paymentProvider=${additionalParams.provider}&currency=${additionalParams.currency}
    // return this._httpClientService.get(`${this.url?.learnersUrl}/v1/payout`, this.httpHeaders).toPromise()
  }
  getPayoutsByOtherCurrency(
    queryParameters?: QueryStringModel,
    additionalParams?: any,
    expertId?: any
  ): any {
    return this._httpClientService
      .get(
        `${this.url?.learnersUrl}/v1/payout-history/${expertId}/admin?pageNo=${queryParameters.pageNumber}&perPage=${queryParameters.pageSize}&otherCurrency=stripe`,
        this.httpHeaders
      )
      .toPromise();
    // &paymentProvider=${additionalParams.provider}&currency=${additionalParams.currency}
    // return this._httpClientService.get(`${this.url?.learnersUrl}/v1/payout`, this.httpHeaders).toPromise()
  }

  doPayout(data: any) {
    return this._httpClientService
      .post(`${this.url?.learnersUrl}/v1/payout/`, data, this.httpHeaders)
      .toPromise();
  }
}
