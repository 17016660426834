import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-user-settings-modal',
  templateUrl: './edit-user-settings-modal.component.html',
  styleUrls: ['./edit-user-settings-modal.component.scss']
})
export class EditUserSettingsModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
