import { Organization } from './models/organization.model';

import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { Designation, _Designation } from './models/designation.model';
import { AppConfiguration } from 'read-appsettings-json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { handleError } from './HttpErrorsHandler/HttpServiceErrorHandler.service';
import { ErrorsTracker } from './models/ErrorsTracker.model';
import { toQueryString } from '../reusable-functions/reusable.functions';


@Injectable()
export class DesignationService {

  url = AppConfiguration.Setting().urls;
  httpheader = {
    headers: new HttpHeaders({
      "content-type": "application/json",
    }),
  };


  designations: Designation[] =[
    {
      id: 123348,
      designation_name: 'Professor',
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 123347,
      designation_name: 'Dr',
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 123346,
      designation_name: 'Mr',
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 123345,
      designation_name: 'Dangote',
      created_at: new Date(),
      updated_at: new Date(),
    }
  ]

  constructor(private _httpService: HttpClient){}

  _getDesignations(): Observable<_Designation[] | ErrorsTracker>{
    return this._httpService.get<_Designation[]>(`${this.url?.marketUrl}/COnfiguration/AllDesignations`,this.httpheader)
    .pipe(
      tap(data => {
        console.log(data)
      }),
     // catchError(handleError)
    );
  } 


  _addDesignation(name: string): Observable<number | ErrorsTracker> {
    return this._httpService
      .post<number>(
        `${this.url?.marketUrl}/Configuration/AddDesignation?${toQueryString({Name:name})}`,null,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
        //catchError(handleError)
      );
  }


  _updateDesignation(designation: _Designation): Observable<number | ErrorsTracker> {
    return this._httpService
      .put<number>(
        `${this.url?.marketUrl}/Configuration/UpdateDesignation?${toQueryString(designation)}`,null,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
       // catchError(handleError)
      );
  }

  addDesignation(data: Designation): Observable<boolean> {
    this.designations.push(data);
    return of(true);
  }


  private mapDesignationProperties(organization: Designation): Designation{
    let organizationInstance = new Designation()
    organizationInstance.id = organization.id;
    organizationInstance.designation_name = organization.designation_name;
    organizationInstance.created_at = organization.created_at;
    organizationInstance.updated_at = organization.updated_at;
    return organizationInstance;
  }


  getDesignations(): Observable<Designation[]> {
    
    return of(this.designations);
  }

  isDesignationAlreadyExisting(data:Designation): Observable<boolean>{
    return of(this.isNameAlreadyExisting(data))
  }

  getDesignation(data:Designation): Observable<Designation>{
    var result = this.designations.find( x => x.id === data.id); 
    return of(this.mapDesignationProperties(result));
  }

  updateDesignation(data: Designation): Observable<boolean>{
    
    var isExist = this.isNameAlreadyExisting(data);

    if(isExist) return of(false);


    var result = this.designations.find( x => x.id === data.id);
    var getIndexPosition = this.designations.indexOf(result);
    result.id = data.id;
    result.designation_name = data.designation_name;
    result.updated_at = new Date();
    result.created_at = data.created_at;
    this.designations.splice(getIndexPosition,1,result)

    return of(true)
}

removeDesignation(data: Designation): Observable<boolean> {
    
  var returnedResult = this.removeItemFromPosition(data);
  return of(returnedResult);
}

private removeItemFromPosition(data: Designation): boolean{
   let i = 0;
   let length = this.designations.length;
   while(i < length){
     if(this.designations[i].id === data.id){
       this.designations.splice(i,1);
       return true
     }
     i++;
   }
   return false;
}
  private isNameAlreadyExisting(data: Designation): boolean{
     
    var result = this.designations.find( x => x.designation_name.toLowerCase() === data.designation_name.toLowerCase());
    if(result) return true;
  
    return false;
  }
}