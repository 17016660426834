import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { CountryEdit } from './modals/country/edit/country.edit.modal';
import { StateModal } from './modals/state/state.modal';
import { CountryModalComponent } from './modals/country/country.modal';
import { StateEdit } from './modals/state/edit/state.edit.modal';
import { CityModal } from './modals/city/city.modal';
import { EditCityModal } from './modals/city/edit/city.edit.modal';
import { OrganizationModal } from './modals/organization/organization.modal';
import { EditOrganizationModal } from './modals/organization/edit/edit.organization.modal';
import { DesignationModal } from './modals/designation/designation.modal';
import { EditDesignationModal } from './modals/designation/edit/edit.designation.modal';
import { ModalInformationPage } from './modals/informationPage/information.page.modal';
import { InformationPageEditModal } from './modals/informationPage/edit/information.page.edit';
import { PricingPlanModal } from './modals/pricingPlan/pricing.plan.modal';
import { PricingPlanEditModal } from './modals/pricingPlan/edit/pricing.plan.edit.modal';
import { PricingDescriptionModal } from './modals/pricingDescription/pricing.descriptions.modal';
import { PricingDescriptionModalEdit } from './modals/pricingDescription/edit/pricing.description.edit.modal';
import { ServiceCategoryDomainModal } from './modals/serviceCategoryDomain/service.category.domain.modal';
import { ServiceCategoryDomainModalEdit } from './modals/serviceCategoryDomain/edit/service.category.domain.edit.modal';
import { ServiceDomainModal } from './modals/serviceDomain/service.domain.modal';
import { ServiceDomainEditModal } from './modals/serviceDomain/edit/service.domain.edit.modal';
import { CustomSessionStatusBadgeComponent } from './components/custom-session-status-badge/custom-session-status-badge.component';
import { CustomSessionPaymentPlanBadgeComponent } from './components/custom-session-payment-plan-badge/custom-session-payment-plan-badge.component';
import { CustomSessionPaymentStatusBadgeComponent } from './components/custom-session-payment-status-badge/custom-session-payment-status-badge.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { CustomTeachableBadgeComponent } from './components/custom-teachable-badge/custom-teachable-badge.component';
import { CoachesProfileComponent } from './components/coaches-profile/coaches-profile.component';
import { CoachesWalletComponent } from './components/coaches-wallet/coaches-wallet.component';
import { RatingModule } from 'ng-starrating';
import { WalletHistoryModalComponent } from './modals/walletHistory/walletHistory.modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CoachesEventsComponent } from './components/coaches-events/coaches-events.component';
import { CoachesEventsModalComponent } from './modals/coachesmodal/coaches-events-detail-modal.component';
import { ProtegeesProfileComponent } from './components/protegees-profile/protegees-profile.component';
import { ProtegeesEnrolledEventsComponent } from './components/protegees-enrolled-events/protegees-enrolled-events.component';
import { ProtegeesWalletComponent } from './components/protegees-wallet/protegees-wallet.component';
import { VideoDisplayModal } from './modals/video-display/video.display.modal';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { TagInputModule } from 'ngx-chips';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { UserSettingsModalComponent } from './modals/user-settings-modal/user-settings-modal.component';
import { EditUserSettingsModalComponent } from './modals/user-settings-modal/edit-user-settings-modal/edit-user-settings-modal.component';




@NgModule({
  imports:[CommonModule, 
    FormsModule, 
    ReactiveFormsModule,
    RatingModule,
    NgxDropzoneModule,
    TabsModule,
    NgSelectModule,
    MomentTimezonePickerModule,
    NgxYoutubePlayerModule.forRoot(),
    FormsModule,
    TagInputModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  
    ],
    
  exports: [CommonModule, FormsModule,
    CustomSessionStatusBadgeComponent,
    CustomSessionPaymentPlanBadgeComponent,
    CustomSessionPaymentStatusBadgeComponent,
    PaginationComponent,CustomTeachableBadgeComponent,
    CoachesProfileComponent,
    CoachesWalletComponent,
    CoachesEventsComponent,
    ProtegeesWalletComponent,
    ProtegeesProfileComponent,
    ProtegeesEnrolledEventsComponent,VideoDisplayModal],
  declarations:[CountryEdit,
    CountryModalComponent,
    StateModal,
    StateEdit,
    CityModal,
    EditCityModal,
    OrganizationModal,
    EditOrganizationModal,
    DesignationModal,
    EditDesignationModal,
    ModalInformationPage,
    InformationPageEditModal,
    PricingPlanModal,
    PricingPlanEditModal,
    PricingDescriptionModal,
    PricingDescriptionModalEdit,
    ServiceCategoryDomainModal,
    ServiceCategoryDomainModalEdit,
    ServiceDomainModal,
    WalletHistoryModalComponent,
    ServiceDomainEditModal,
    CustomSessionStatusBadgeComponent,
    CustomSessionPaymentPlanBadgeComponent,
    CustomSessionPaymentStatusBadgeComponent,
    PaginationComponent,
    CustomTeachableBadgeComponent,
    CoachesProfileComponent,
    CoachesWalletComponent,
    CoachesEventsComponent,
    CoachesEventsModalComponent,
    ProtegeesProfileComponent,
    ProtegeesEnrolledEventsComponent,
    ProtegeesWalletComponent,
    VideoDisplayModal,
    UserSettingsModalComponent,
    EditUserSettingsModalComponent,
    
    
    ]
    
})
export class SharedModule{
  
}