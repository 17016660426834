import { Component, Input, OnInit } from '@angular/core';
import { ProtegeeDetail } from 'src/app/core/services/models/protegee.model';

@Component({
  selector: 'app-protegees-profile',
  templateUrl: './protegees-profile.component.html',
  styleUrls: ['./protegees-profile.component.scss']
})
export class ProtegeesProfileComponent implements OnInit {

  @Input() _protegee$:any;
  @Input() _profession$:string[];
  constructor() { }

  ngOnInit(): void {
  }

}
