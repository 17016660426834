import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from "@angular/core";

import { Designation, _Designation } from 'src/app/core/services/models/designation.model';

@Component({
  selector: 'app-edit-organization',
  templateUrl: 'edit.designation.modal.html'
})
export class EditDesignationModal implements OnInit{

  focus;
  form: FormGroup;
  localOrganization: _Designation;
  @Input() public designation:_Designation;

  constructor(private _modalActive:NgbActiveModal){

  }

  ngOnInit(): void{

    this.form = new FormGroup({
      'designation_name': new FormControl({value: this.designation.name,disabled:false},[Validators.required, Validators.min(3)])
    })
  }

  get designation_name(){
    return this.form.get('designation_name')
  }
  onSubmit(){
    this.localOrganization = new  _Designation();

    this.localOrganization.id = this.designation.id;
    this.localOrganization.name= this.form.value.designation_name;
    

    this._modalActive.close(this.localOrganization);
    
  }

  dismiss(){
    this._modalActive.dismiss('Cross click');
  }
}