import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProtegeeDetail } from 'src/app/core/services/models/protegee.model';
import { CoachesEventsModalComponent } from '../../modals/coachesmodal/coaches-events-detail-modal.component';

@Component({
  selector: 'app-protegees-enrolled-events',
  templateUrl: './protegees-enrolled-events.component.html',
  styleUrls: ['./protegees-enrolled-events.component.scss']
})
export class ProtegeesEnrolledEventsComponent implements OnInit {

  @Input() _protegee$:any;
  constructor(private _modalService:NgbModal) { }

  ngOnInit(): void {
  }

  onRate($event){

  }

  viewEventDetail(event){
    console.log('Event cliecked upon', event)
    let modelReturned = this._modalService.open(CoachesEventsModalComponent,{ size: 'xl',centered: true});
      modelReturned.componentInstance.event = event;

  }

}
