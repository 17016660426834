import { Component , OnInit, Input} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceDomainCategory, _ServiceDomainCategory } from 'src/app/core/services/models/service.doamin.category.model';
import noUiSlider from "nouislider";
import Dropzone from "dropzone";
Dropzone.autoDiscover = false;
import Quill from "quill";
import Selectr from "mobius1-selectr";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-service-domain-category-modal-edit',
  templateUrl: 'service.category.domain.edit.modal.html',
})
export class ServiceCategoryDomainModalEdit implements OnInit{
  private base64textString:String="";
  base64: string = "Base64...";
  imageUrl?: string;
  fileSelected?: File;
  form:FormGroup;
  focus;
  localCategoryDomain: _ServiceDomainCategory;
  @Input() public domainCategory:_ServiceDomainCategory;

  constructor(public _modal: NgbActiveModal,private sant: DomSanitizer){}

  ngOnInit(): void{
    this.form = new FormGroup({
      'id':new FormControl({value :this.domainCategory.id, disabled:false},[Validators.required]),
      'name':new FormControl({value :this.domainCategory.serviceDomainName, disabled:false},[Validators.required]),
      'description': new FormControl({value :this.domainCategory.description, disabled:false},[Validators.required]),
      
    })
     
    this.imageUrl = this.domainCategory.imageBase64String;
    
  }

  get imageView(){
    console.log("was it gotten?",this.domainCategory.imageBase64String)
    return this.domainCategory.imageBase64String;
  }

  get id(){
    return this.form.get('id');
  }

  get created_at(){
    return this.form.get('created_at');
  }

  get name(){
    return this.form.get('name');
  }

  get description(){
    return this.form.get('description');
  }


  dismiss(){
    this._modal.dismiss();
   }
 
  
   files: File[] = [];

	onSelect(event) {
	
		this.files.push(...event.addedFiles);

    this.fileSelected=  this.files[0]
    this.imageUrl = this.sant.bypassSecurityTrustUrl(window.URL.createObjectURL(this.fileSelected)) as string ;
    this.base64 = "Base64...";
    this.domainCategory.imageBase64String = this.sant.bypassSecurityTrustUrl(window.URL.createObjectURL(this.fileSelected)) as string;
	}

	onRemove(event) {
		console.log(event);
		this.files.splice(this.files.indexOf(event), 1);
	}

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString= btoa(binaryString) as string;
    //console.log(btoa(binaryString));
   }

   getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  onSubmit(){
    
     
    this.getBase64(this.files[0]).then(data =>{
    
     let innerData = data;
     console.log("Inner data ==> ", innerData as string)
     
     this.localCategoryDomain = new _ServiceDomainCategory()
     this.localCategoryDomain.serviceDomainName = this.form.value.name;
     this.localCategoryDomain.description = this.form.value.description;
     this.localCategoryDomain.imageBase64String = data;
     this.localCategoryDomain.id = this.domainCategory.id

    console.log('domain known ==> ',this.localCategoryDomain)

     this._modal.close(this.localCategoryDomain);

   } )
  }


}