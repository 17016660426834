import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { promise } from "protractor";
import { Observable, of } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private securityServices: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> {
    // let claimType: string= next.data["claimType"];'/auth','login'
    var item = localStorage.getItem("bearerToken");
    const isTokenExpired = this.securityServices.isTokenExpired(
      JSON.parse(item).authToken
    );
    if (isTokenExpired === true) {
      localStorage.removeItem("bearerToken");
      return this.router.navigate(["/auth/login"]);
    }
    if (item) {
      return of(true);
    } else {
      this.router.navigate(["/auth/login"], {
        queryParams: { returnUrl: state.url },
      });
    }
  }
}
