import { combineLatest, Observable, of } from 'rxjs';
import {
  ServiceDomain,
  ServiceDomainVm,
  _ServiceDomain,
  _ServiceDomain_ServiceDomainCategory
} from './models/service.domain.model';
import { Injectable } from '@angular/core';
import { ServiceDomainCategoryService } from './service.doamin.category.service';
import { AppConfiguration } from 'read-appsettings-json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import {
  handleError,
  handleErrorAny
} from './HttpErrorsHandler/HttpServiceErrorHandler.service';
import { toQueryString } from '../reusable-functions/reusable.functions';

@Injectable()
export class ServiceDomainService {
  url = AppConfiguration.Setting().urls;
  httpHeaders = {
    headers: new HttpHeaders({
      'content-type': 'application/json'
    })
  };

  constructor(
    private _domainCategoryService: ServiceDomainCategoryService,
    private _httpClientService: HttpClient
  ) {}

  private serviceDomainServices: ServiceDomain[] = [
    {
      id: 569903,
      service_domain_name: 'CFA',
      description: 'get professional with your cfa',
      service_domain_category_id: 334552,
      created_at: new Date(),
      updated_at: new Date()
    },
    {
      id: 569963,
      service_domain_name: 'I C A N',
      description: 'get professional at lol',
      service_domain_category_id: 334552,
      created_at: new Date(),
      updated_at: new Date()
    },
    {
      id: 569362,
      service_domain_name: 'Artificial Intelligence ',
      description: 'be part of the growing world',
      service_domain_category_id: 334572,
      created_at: new Date(),
      updated_at: new Date()
    },
    {
      id: 569964,
      service_domain_name: 'Data Science',
      description: 'Analyze data with insight',
      service_domain_category_id: 334572,
      created_at: new Date(),
      updated_at: new Date()
    }
  ];

  _getServiceDomain() {}

  _addServiceDomain(serviceDomain: _ServiceDomain) {
    return this._httpClientService
      .post<number>(
        `${this.url?.marketUrl}/Configuration/AddServiceDomain?${toQueryString(
          serviceDomain
        )}`,
        null,
        this.httpHeaders
      )
      .pipe(
        tap((data) => {
          console.log(data);
        })
        //catchError(handleError)
      );
  }

  _updateServiceDomain(serviceDomain: _ServiceDomain) {
    return this._httpClientService
      .put<number>(
        `${
          this.url?.marketUrl
        }/Configuration/UpdateServiceDomain?${toQueryString(serviceDomain)}`,
        null,
        this.httpHeaders
      )
      .pipe(
        tap((data) => {
          console.log(data);
        })
        //catchError(handleError)
      );
  }

  _getServiceDomain$ = this._httpClientService
    .get<_ServiceDomain[]>(
      `${this.url?.marketUrl}/Configuration/AllServiceDomains`,
      this.httpHeaders
    )
    .pipe(
      tap((data) => {
        console.log(data);
      })
      //catchError(handleErrorAny)
    );

  __getServiceDomain_DomainCategory$ = combineLatest([
    this._getServiceDomain$,
    this._domainCategoryService._getserviceDomainCategory$
  ]).pipe(
    map(([_doamin, _categoryDomain]) =>
      _doamin.map(
        (domain) =>
          ({
            id: domain.id,
            name: domain.name,
            description: domain.description,
            serviceDomainCategoryId: domain.serviceDomainCategoryId,
            service_domain_category_name: domain.serviceDomainCategoryName
          } as _ServiceDomain_ServiceDomainCategory)
      )
    )
  );

  // ServiceDomainServiceAlreadyExist(data: ServiceDomain): Observable<boolean>{
  //   let result = this._getServiceDomainServices.find(x => x.service_domain_name.toLowerCase() == data.service_domain_name.toLowerCase());

  //   if(result) return of(true);

  //   return of(false);
  // }

  UpdateServiceDomain(data: ServiceDomain): Observable<boolean> {
    let indexToFind = this.serviceDomainServices.findIndex(
      (x) => x.id == data.id
    );

    this.serviceDomainServices.splice(indexToFind, 1, data);
    return of(true);
  }

  //private methods starts
  privateGetMappedServiceDomainServices(
    plans: ServiceDomain[]
  ): ServiceDomainVm[] {
    return plans.map((result) => {
      let newlyMappedServiceDomain = new ServiceDomainVm();
      newlyMappedServiceDomain.id = result.id;
      newlyMappedServiceDomain.service_domain_category_id =
        result.service_domain_category_id;
      newlyMappedServiceDomain.service_domain_category_name =
        this._domainCategoryService._getServiceDomainCategories.find(
          (x) => x.id == result.service_domain_category_id
        ).name;
      newlyMappedServiceDomain.service_domain_name = result.service_domain_name;
      newlyMappedServiceDomain.description = result.description;
      newlyMappedServiceDomain.created_at = new Date();
      newlyMappedServiceDomain.updated_at = new Date();

      return newlyMappedServiceDomain;
    });
  }

  privateGetMappedServiceDomainService(result: ServiceDomainVm): ServiceDomain {
    let newlyMappedServiceDomain = new ServiceDomain();
    newlyMappedServiceDomain.id = result.id;
    newlyMappedServiceDomain.description = result.description;

    newlyMappedServiceDomain.service_domain_category_id =
      result.service_domain_category_id;

    newlyMappedServiceDomain.service_domain_name = result.service_domain_name;
    newlyMappedServiceDomain.created_at = new Date();
    newlyMappedServiceDomain.updated_at = new Date();

    return newlyMappedServiceDomain;
  }

  privateGetMappedPricingSessionPlanVm(result: ServiceDomain): ServiceDomainVm {
    let newlyMappedServiceDomain = new ServiceDomainVm();
    newlyMappedServiceDomain.id = result.id;
    newlyMappedServiceDomain.service_domain_category_id =
      result.service_domain_category_id;

    newlyMappedServiceDomain.description = result.description;
    newlyMappedServiceDomain.service_domain_category_name =
      this._domainCategoryService._getServiceDomainCategories.find(
        (x) => x.id == result.service_domain_category_id
      ).name;
    newlyMappedServiceDomain.service_domain_name = result.service_domain_name;
    newlyMappedServiceDomain.created_at = new Date();
    newlyMappedServiceDomain.updated_at = new Date();

    return newlyMappedServiceDomain;
  } //private methods ends

  get _getServiceDomainServices(): ServiceDomain[] {
    return this.serviceDomainServices;
  }

  getServiceDomainServices(): Observable<ServiceDomainVm[]> {
    return of(
      this.privateGetMappedServiceDomainServices(this.serviceDomainServices)
    );
  }

  AddServiceDomainService(data: ServiceDomainVm): Observable<boolean> {
    this.serviceDomainServices.push(
      this.privateGetMappedServiceDomainService(data)
    );
    return of(true);
  }

  getServiceDomainCategory(data: ServiceDomain): Observable<ServiceDomain> {
    return of(
      this.privateGetMappedPricingSessionPlanVm(
        this.serviceDomainServices.find((x) => x.id == data.id)
      )
    );
  }

  deleteServiceDomainCategory(data: ServiceDomain): Observable<boolean> {
    var dataIndex = this.serviceDomainServices.findIndex(
      (x) => x.id == data.id
    );
    this.serviceDomainServices.splice(dataIndex, 1);

    return of(true);
  }
}
