import { Router } from '@angular/router';
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CountryService } from "src/app/core/services/country.service";
import { Country, _Country } from "src/app/pages/settings/components/country/model/country.model";
import { AlertService } from 'src/app/core/services/alert.service';
import { ErrorsTracker } from 'src/app/core/services/models/ErrorsTracker.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-country-edit',
  templateUrl:'country.edit.modal.html',
})
export class CountryEdit implements OnInit {
  focus;
  form;
  @Input() country:Country;
  countryMap: _Country = new _Country();

  constructor(public activeModal: NgbActiveModal,
    private _countryService: CountryService,
    private _route: Router,
    private _alert:  AlertService,
    private spinner: NgxSpinnerService){}

  ngOnInit(){
     this.form = new FormGroup({
      id: new FormControl({value : this.country.id , disabled:true}),
      country_name: new FormControl(this.country.name, [
        Validators.required,
        Validators.minLength(3),
      ]),
      created_at: new FormControl({value : this.country.created_at , disabled:true}),
      updated_at: new FormControl({value : this.country.updated_at , disabled:true})
    });
  
  }
  close(){
    this.activeModal.close();
  }

  dismiss(){
    this.activeModal.dismiss();
  }

 
  get country_name() {
    return this.form.get('country_name');
  }

  get id() {
    return this.form.get('id');
  }


  get created_at() {
    return this.form.get('created_at');
  }

  get updated_at() {
    return this.form.get('updated_at');
  }



  onSubmit(){
    this.countryMap.name = this.country_name.value;
    this.countryMap.id = this.id.value;
   
    this.activeModal.close(this.countryMap);

  }
}