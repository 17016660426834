import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CoachService } from 'src/app/core/services/coach.service';
import { CoachDetail, returnedResult } from 'src/app/core/services/models/coache.model';
import { ErrorsTracker } from 'src/app/core/services/models/ErrorsTracker.model';
import { CustomSpinnerService } from 'src/app/core/services/spinner.custom.service';
import { VideoDisplayModal } from '../../modals/video-display/video.display.modal';

const timeList = [
  { time: '12:00 AM', value: '0.0' },
  { time: '12:30 AM', value: '0.5' },
  { time: '1:00 AM', value: '1.0' },
  { time: '1:30 AM', value: '1.5' },
  { time: '2:00 AM', value: '2.0' },
  { time: '2:30 AM', value: '2.5' },
  { time: '3:00 AM', value: '3.0' },
  { time: '3:30 AM', value: '3.5' },
  { time: '4:00 AM', value: '4.0' },
  { time: '4:30 AM', value: '4.5' },
  { time: '5:00 AM', value: '5.0' },
  { time: '5:30 AM', value: '5.5' },
  { time: '6:00 AM', value: '6.0' },
  { time: '6:30 AM', value: '6.5' },
  { time: '7:00 AM', value: '7.0' },
  { time: '7:30 AM', value: '7.5' },
  { time: '8:00 AM', value: '8.0' },
  { time: '8:30 AM', value: '8.5' },
  { time: '9:00 AM', value: '9.0' },
  { time: '9:30 AM', value: '9.5' },
  { time: '10:00 AM', value: '10.0' },
  { time: '10:30 AM', value: '10.5' },
  { time: '11:00 AM', value: '11.0' },
  { time: '11:30 AM', value: '11.5' },
  { time: '12:00 PM', value: '12.0' },
  { time: '12:30 PM', value: '12.5' },
  { time: '1:00 PM', value: '13.0' },
  { time: '1:30 PM', value: '13.5' },
  { time: '2:00 PM', value: '14.0' },
  { time: '2:30 PM', value: '14.5' },
  { time: '3:00 PM', value: '15.0' },
  { time: '3:30 PM', value: '15.5' },
  { time: '4:00 PM', value: '16.0' },
  { time: '4:30 PM', value: '16.5' },
  { time: '5:00 PM', value: '17.0' },
  { time: '5:30 PM', value: '17.5' },
  { time: '6:00 PM', value: '18.0' },
  { time: '6:30 PM', value: '18.5' },
  { time: '7:00 PM', value: '19.0' },
  { time: '7:30 PM', value: '19.5' },
  { time: '8:00 PM', value: '20.0' },
  { time: '8:30 PM', value: '20.5' },
  { time: '9:00 PM', value: '21.0' },
  { time: '9:30 PM', value: '21.5' },
  { time: '10:00 PM', value: '22.0' },
  { time: '10:30 PM', value: '22.5' },
  { time: '11:00 PM', value: '23.0' },
  { time: '11:30 PM', value: '23.5' },
];

@Component({
  selector: 'app-coaches-profile',
  templateUrl: './coaches-profile.component.html',
  styleUrls: ['./coaches-profile.component.scss']
})
export class CoachesProfileComponent implements OnInit {


  selectedRow;
  _hourlyRate;
  availabilityArray: any[]
  @Input() _coach$: any;
  @Input() _profession$: string[];
  constructor(
    private modal: NgbModal,
    private _coachesService: CoachService,
    private customSpinner: CustomSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private toater: ToastrService) { }


  ngOnInit(): void {
    console.log("Coach profile from ", this._coach$)
  }

  ngOnChanges(changes: any) {
    console.log({ changes })
    for (let propName in changes) {
      if (propName === "_coach$") {
        let chng = changes[propName];
        let scheduling = chng?.currentValue?.profilePages?.availabilityPage?.serviceScheduling
        const availArr = []
        if(scheduling){
          for(let schedule in scheduling){
            if(scheduling[schedule].active){
              let data = this.getAvailableTime(schedule, scheduling[schedule])
              availArr.push(data)
            }
          }
        }
        this.availabilityArray = availArr
      }
    }
  }
  seeHourlyRate(plan) {
    this.selectedRow = plan.planName;
    this._hourlyRate = plan.hourlyRate;
  }

  onRate($event) {

  }

  viewCoachVideo(url) {
    console.log(url)

    if (url == null) {
      this.toater.error("no video available to display");
      return;
    }
    if (url?.includes("youtube") || url?.includes("youtu.be")) {
      //console.log(this.getLastId(url))

      if (url.includes('v=')) {
        let youtubeId = url.split("v=")[1];
        console.log('splitted youtube Id ==>', youtubeId)
        let result = this.modal.open(VideoDisplayModal, { centered: true, size: 'xl' });
        result.componentInstance.videoUrl = { url: null, youtubeId: youtubeId };
      } else if (!url.includes('v=')) {
        let youttubeId = this.getLastId(url);
        if (youttubeId) {
          console.log('Yputube Id ', youttubeId)
          let result = this.modal.open(VideoDisplayModal, { centered: true, size: 'xl' });
          result.componentInstance.videoUrl = { url: null, youtubeId: youttubeId };
        }
      }

    } else {
      let result = this.modal.open(VideoDisplayModal, { centered: true, size: 'xl' });
      result.componentInstance.videoUrl = { url: url, youtubeId: null };
    }
  }
  getLastId(words: string): string {
    let concat = '';
    for (let i = words.length - 1; i > 0; i--) {


      if (words[i] == '/') {

        return concat;

      }

      concat = concat + words[i]
    }

  }

  getWeekDay(day: any) {
    switch (day) {
      case 1:
        return 'monday'
      case 2:
        return 'tuesday'
      case 3:
        return 'wednesday'
      case 4:
        return 'thursday'
      case 5:
        return 'friday'
      case 6:
        return 'saturday'
      case 0:
        return 'sunday'
      default:
        return 'sunday'
    }
  }

  getTimeRange(hour: any = [], from: any, to: any, listParam = "value") {
    const compareFromValue = listParam === "value" ? String(hour[from]?.toFixed(1)) : String(hour[from])
    const compareToValue = listParam === "value" ? String(hour[to]?.toFixed(1)) : String(hour[to])

    const fromIndex = timeList.findIndex((time: any) => time[listParam] === compareFromValue)
    const toIndex = timeList.findIndex((time: any) => time[listParam] === compareToValue)

    let hoursList = timeList.slice(fromIndex, (toIndex + 1));
    return hoursList.map((list) => list.time);
  }

  getAvailableTime(day, searchData) {
    if (searchData?.active) {
      let newHours = this.getTimeRange(searchData.hours[0], "from", "to")
      return {
        day: day,
        hours: newHours
      }
    }
  }
  activateCoach(event, coach) {
    //console.log('is active? --> ',event.target.checked)
    // console.log('is id? --> ',coach.id)
    this.customSpinner.showSpinner();

    this._coachesService.approveCoach({ coachId: coach.id, coachEmail: coach.email })
      .subscribe((res: returnedResult) => {
        res.isApproved ? this.toater.success(`"coach ${coach.coachFullName}" approved!`) : this.toater.success(`"coach ${coach.name}" not approved`);
        console.log(res);
        this.customSpinner.hideSpinnerFor1000MlSeconds();
      },
        (error: ErrorsTracker) => {
          this.toater.error(error._friendlyMessage);
          this.customSpinner.hideSpinnerFor1000MlSeconds();
        })
  }

  params = {
    autoplay: 1,
    muted: 1,
    controls: 0,
  };

  onMessage(event: CustomEvent<any>) {
    const message = event.detail;
    // ...
  }

}
