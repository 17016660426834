export class State {
  id: number;
  state_name:string;
  country_id: number;
  created_at: Date;
  updated_at: Date;
}

export class StateView{
  id: number;
  state_name:string;
  country_id: number;
  country_name: string;
  created_at: Date;
  updated_at: Date;
}

export class _State{
  stateId:number;
  stateName: string;
  countryId: number;
}

export class _StateWithCountry{
  id:number;
  countryId: number;
  stateName: string;
  countryName: string;
}