export class WordSettings{
  mainWord:number;
  subWord: number;
  urls:Urls
}

export class Urls{
  adminUrl:string;
  marketUrl:string;
  learnersUrl:string;
}
