import { CountryService } from 'src/app/core/services/country.service';
import {Component, Input, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { State, _State, _StateWithCountry } from 'src/app/pages/settings/components/state/model/state.model';
import { Country, _Country } from 'src/app/pages/settings/components/country/model/country.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorsTracker } from 'src/app/core/services/models/ErrorsTracker.model';

@Component({
  selector: 'app-edit-modal',
  templateUrl: 'state.edit.modal.html'
})
export class StateEdit implements OnInit {
  @Input() public state: _StateWithCountry;
  form: FormGroup;
  focus:boolean;
  stateValue: _State = new _State();
  countryList: _Country[] = [];
 
  
  constructor(public _modal: NgbActiveModal,
    private _countryService: CountryService,
    private spinner: NgxSpinnerService){
      
   
  }

  ngOnInit(){
    console.log('Addnnn -->',this.state)

    this.form = new FormGroup({
      id:new FormControl({value :this.state.id,disabled:true}),
      country_id: new FormControl({value : this.state.countryId,disabled:false}),
      state_name: new FormControl({value : this.state.stateName,disabled:false},[Validators.required,Validators.minLength(3)]),
    })
    this.spinner.show();
    this._countryService._getCountries().subscribe((data: _Country[]) => {
     
      this.countryList = data;
      this.spinner.hide();
    },
    (error: ErrorsTracker) => {
      
      console.log('Somethinf went wrong at pulling of country in state modal', error)
    })
  }

  close(){
    this._modal.close();
  }

  dismiss(){
    this._modal.dismiss();
  }

  get updated_at(){
    return this.form.get('updated_at');
  }
  get created_at(){
    return this.form.get('created_at')
  }
  get state_name(){
    return this.form.get('state_name');
  } 

  get country_id(){
    return this.form.get('country_id')
  }

  get id(){
    return this.form.get('id');
  }

  onSubmit(){
    this.stateValue.stateId =this.id.value;
    this.stateValue.countryId = +this.country_id.value;
    this.stateValue.stateName = this.state_name.value;
  
    console.log('--> ', this.stateValue)
   this._modal.close(this.stateValue);
  }
}