import { Country, _Country } from './../../../pages/settings/components/country/model/country.model';
import { State, _State } from './../../../pages/settings/components/state/model/state.model';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { CityService } from 'src/app/core/services/city.service';
import { CountryService } from './../../../core/services/country.service';
import { StateService } from './../../../core/services/state.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import * as $ from 'jquery';

import { TimeZoneService } from 'src/app/core/services/time-zone.service';
import { City, _City } from 'src/app/pages/settings/components/city/model/city.model';
import { ErrorsTracker } from 'src/app/core/services/models/ErrorsTracker.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-modal-city',
  templateUrl: './city.modal.html',
  styleUrls:['./city.modal.css']
})
export class CityModal implements OnInit {
 
  form;
  focus;
  countryId:number;
  city: _City;
  states: _State[] = [];
  countries: _Country[] = [];
  selectedPersonId = '5a15b13c36e7a7f00cf0d7cb';
  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
];
  
  constructor(
    public _modalActive: NgbActiveModal,
    private _stateService: StateService,
    private _countryService: CountryService,
    private _cityService: CityService,
    private _timeZoneService: TimeZoneService,
    private spinner: NgxSpinnerService,
    private alert: AlertService
  ) {
    this.spinner.show()
     // get states
     this._stateService._getStates().subscribe((result:_State[]) => {

      console.log('states returned ' , result)
      this.states = result;
      this.spinner.hide();
    },
    (error: ErrorsTracker) => {
      this.spinner.hide();
      this.alert.dangerAlert(error.friendlyMessage)
      console.log('error from city modal', error)
    });

    // get countries
    this._countryService._getCountries().subscribe((result: _Country[]) => {
      console.log('countries returned ' , result)
      this.countries = result;
    },
    (error: ErrorsTracker) => {
      this.spinner.hide();
      this.alert.dangerAlert(error.friendlyMessage)
      console.log('error from city modal', error)
    })
    

  }





  ngOnInit(): void {
    this.userTz = moment.tz.guess();
    this.tzNames = moment.tz.names();
   
    this.form = new FormGroup({
      state_id: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      country_id: new FormControl({value :this.countryId,disabled:true}),
      city_name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      selectedCar: new FormControl({value :'5a15b13c36e7a7f00cf0d7cb',disabled:false}, [
        Validators.required,
        Validators.minLength(3),
      ]),
      yourModel: new FormControl({value :'',disabled:false}, [
        Validators.required,
        Validators.minLength(3),
      ]),
      timezone: new FormControl({value :'',disabled:false}, [
        Validators.required,
        Validators.minLength(3),
      ]),
    });
  }

  get state_id() {
    return this.form.get('state_id');
  }

  get selectedCar() {
    return this.form.get('selectedCar');
  }

  get yourModel() {
    return this.form.get('yourModel');
  }

  get country_id() {
    return this.form.get('country_id');
  }

  get city_name() {
    return this.form.get('city_name');
  }

  get timezone() {
    return this.form.get('timezone');
  }

  onSubmit() {
    console.log('the value returned  --> ',this.form.value.yourModel)
    let {abbr} =  this.form.value.yourModel;
      this.city = new _City();
  
      this.city.countryId = this.countryId;
      this.city.stateId = this.form.value.state_id;
      this.city.cityName = this.form.value.city_name;
      this.city.timezoneName = abbr
      console.log('city log --> ', this.city);
      this._modalActive.close(this.city);
  }

  getCountry(stateValue) {
  
    let state= this.states.find(x => x.stateId == stateValue);
    console.log('state returned ', state)
    let country= this.countries.find(x => x.id == state.countryId);
    console.log('state changed', country);
    this.countryId =  country.id;
    this.form.patchValue({'country_id':country.id});
  }

  dismiss() {
    this._modalActive.dismiss();
  }

  close() {
    this._modalActive.close();
  }

  changeTimezone($event){
    
  }

  public tzNames: string[];

  public userTz: string;

  public selectedTz: string;

  public selectedLocale: string;

  // timeZoneChanged(timeZone: string){
  //   console.log(timeZone);
  //   this.selectedTz = timeZone;

  //   //this.updateTime(timeZone);
  // }
  private generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

}
