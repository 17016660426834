export class ServiceDomainCategory{
  id: number;
  name: string;
  description: string;
  created_at: Date;
  updated_at: Date;
}



export class _ServiceDomainCategory{
  id: number;
  serviceDomainName: string;
  description: string;
  imageBase64String:any;
 
}

export class RedisServiceCategory{
  public categories:string[];
}