import { Event } from './event.model';
import { PlanRate } from './planrates.model';
import { Sessions } from "./sessions.model";


export class CoachModel{
  public totalRecord: number;
  public currentPageCount: number;
  public currentPageSize: number;
  public coaches:Coach[]
}



export class ReturnedCoachModel{
  public totalRecord: number;
  public currentPageCount: number;
  public currentPageSize: number;
  public coach:Coach
}


export class Coach{

  public id: number;
  public coachFullName: string;
  public userName: string;
  public email: string;
  public dateJoined: string;
  public numberOfEvents: number;
  public numberOfSessions: number;
  public ratePerHour: number;
  public walletBalance: number;
  public walletCurrencyCode: number;
  public coachStatus: string;
  public rating: number;
  public imageUrl: string;
  public roleName: string;
  public companyName: string;
  public catchyAchievementPhrase: string;
  public coachIntroductoryVideoUrl: string;
  public coachPricingPlans: number[];
  public isCoachMailVerified: boolean;
  public isCoachRegistrationCompleted : boolean;
  public isApproved: boolean;
}



export class CoachDetail{

  coach: Coach;
  privateSession:Sessions;
  events:Event[];
  planRates:PlanRate[];
}

export class returnedResult{
  isApproved: boolean;
}