import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-video-display',
    templateUrl: './video.display.modal.html'
})
export class VideoDisplayModal implements OnInit{
    videoId = 'lKM-8CZRplI';
    @Input() public videoUrl;
    isYoutubeUrl: boolean= false;
    youtubeId: string;
    sanitizeUrl;
    constructor(public activeModal: NgbActiveModal, 
        private _sanitizer: DomSanitizer,
        private _activatedRoute: ActivatedRoute){

           

    }

    player: YT.Player;
    ngOnInit(){
        
      console.log("video url", this.videoUrl)
       // this.sanitizeUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/tgbNymZ7vqY');
    }

    // ngAfterViewInit(){
    //     this.youtubeId = this.readQueryString(this.videoUrl);
    //     if(this.videoUrl.includes("you")){
    //         console.log("yesss.. its youtube url..")
    //         this.isYoutubeUrl = true;
    //     }
    // }

    readQueryString(url) {
        console.log('video url extracted --> ',this.videoUrl)
       
        return this.videoUrl.split("v=")[1];
      
        
     }

     savePlayer(player){
        this.player = player;
        console.log('player instance', player)
     }

     onStateChange(event){
        console.log('player state' , event.data)
     }
}