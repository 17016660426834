import { ServiceDomainCategory, _ServiceDomainCategory } from './../../../core/services/models/service.doamin.category.model';
import { OnInit,Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceDomain, _ServiceDomain } from 'src/app/core/services/models/service.domain.model';
import { ServiceDomainCategoryService } from 'src/app/core/services/service.doamin.category.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-service-domain-modal',
  templateUrl: 'service.domain.modal.html'
})
export class ServiceDomainModal implements OnInit{

  form:FormGroup;
  focus;
  localServiceDomain: _ServiceDomain
  localDomainCategoryList: _ServiceDomainCategory[]= [];
  
  constructor(public _modal: NgbActiveModal,
    private _serviceDomainCategory: ServiceDomainCategoryService,
    private spinner: NgxSpinnerService){}

  ngOnInit(): void{

    // get domain category
    this.spinner.show();
    this._serviceDomainCategory._getserviceDomainCategory$.subscribe(result => {
      this.localDomainCategoryList = result;
      this.spinner.hide();
    })
    this.form = new FormGroup({
      'service_domain_name':new FormControl('',[Validators.required]),
      'description': new FormControl('',[Validators.required]),
      'service_domain_category_id': new FormControl('',[Validators.required])
    })
     
  }

  get service_domain_name(){
    return this.form.get('service_domain_name');
  }

  get description(){
    return this.form.get('description');
  }

  get service_domain_category_id(){
    return this.form.get('service_domain_category_id');
  }

  
  dismiss(){
   this._modal.dismiss();
  }

  onSubmit(){
    this.localServiceDomain = new _ServiceDomain()
    this.localServiceDomain.name = this.form.value.service_domain_name;
    this.localServiceDomain.serviceDomainCategoryId = this.form.value.service_domain_category_id;
    this.localServiceDomain.description = this.form.value.description;
    
    //console.log('domain known ==> ',this.localServiceDomain)
    this._modal.close(this.localServiceDomain);
  }

  getDomainCategory($event){

  }

  private generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }
}