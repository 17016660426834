import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-settings-modal',
  templateUrl: './user-settings-modal.component.html',
  styleUrls: ['./user-settings-modal.component.scss']
})
export class UserSettingsModalComponent implements OnInit {

  form:FormGroup;
  focus;
  isEdit = false
  @Input() public userSetting: any;

  constructor(
    private spinner: NgxSpinnerService,
    public _modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    console.log({userSetting: this.userSetting})
    if(this.userSetting){
      this.form = new FormGroup({
        'settingName':new FormControl(this.userSetting.settingName,[Validators.required,  this.noWhitespaceValidator]),
        'settingValue': new FormControl(this.userSetting.settingValue,[Validators.required,  this.noWhitespaceValidator]),
      })
      this.isEdit = true
    }else{
      this.form = new FormGroup({
        'settingName':new FormControl('',[Validators.required,  this.noWhitespaceValidator]),
        'settingValue': new FormControl('',[Validators.required,  this.noWhitespaceValidator]),
      })
    }
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
  get settingName(){
    return this.form.get('settingName');
  }

  get settingValue(){
    return this.form.get('settingValue');
  }

  dismiss(){
    this._modal.dismiss();
   }

   onSubmit(){
    const dataToSend = {
      "settingName": this.form.value.settingName,
      "settingValue": this.form.value.settingValue,
      "settingValueType": "2",
      "settingType": "1"
    }

    this._modal.close(dataToSend);
  }
}
