import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertService } from "src/app/core/services/alert.service";

@Component({
    selector:'app-wallet-history',
    templateUrl: './walletHistory.modal.html'
})
export class WalletHistoryModalComponent implements OnInit{

    

    constructor(
        public activeModal: NgbActiveModal,
       
        private spinner: NgxSpinnerService,
        private router: Router,
        private toaster: AlertService
      ) {}


    ngOnInit(){
        
    }
}