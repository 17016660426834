import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { WordSettings } from './models/app.settings.model';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn:'root'

})
export class AppSettingsService{

  SETTINGS_LOCATION: string = "assets/appsettings.json";
  constructor(private _httpClient: HttpClient){

  }

  getWordSettingsSetting(): Observable<WordSettings>{
    return this._httpClient.get<WordSettings>(this.SETTINGS_LOCATION)
    .pipe(
      tap(data => {
        console.log('settings data returned =>', data)
      },
      err => {
        console.log(err)
      })
    )
  }

}