import { InformationTextPage, _InformationTextPage } from 'src/app/core/services/models/Information.page.text.model';
import { WordSettings } from 'src/app/core/services/models/app.settings.model';
import { AppSettingsService } from 'src/app/core/services/app.settings.service';


import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-information-page',
  templateUrl: 'information.page.edit.html'
})
export class InformationPageEditModal implements OnInit{
  
  focus;
  isActiveResponse:boolean = false;
  localModel:_InformationTextPage; 
  localValidator: WordSettings = new WordSettings();
  @Input() public localInformationText : _InformationTextPage;
  constructor(public _modal : NgbActiveModal,
    private _AppSettingsService: AppSettingsService){
    
  }


  ngOnInit(): void{
   
    console.log('passed in local information --> ', this.localInformationText)

    
    this.isActiveResponse = this.localInformationText.activeText;

    console.log('active response passed ==> ', this.isActiveResponse)
    // load app settings 
    this._AppSettingsService.getWordSettingsSetting().subscribe(
      x => {
        this.localValidator = x;
       
        console.log('returned from settings ', this.localValidator)
      }
    )
    
  }

  onSubmit(f){
    this.localModel = new _InformationTextPage();
    this.localModel = f.value;

    this.localModel.activeText = this.isActiveResponse;
   

    console.log('edited modal for local model --> ', this.localModel)
    //this._modal.close(this.localModel);
    this.isActiveResponse =  !this.isActiveResponse;

    this._modal.dismiss();
    alert('infomation page update is yet to be done', )
  }


  dismiss(){
    this._modal.dismiss();
  }

  isActiveChnaged($event){
    if($event.target.checked === true  || $event.target.checked === false){
      this.isActiveResponse = $event.target.checked
    }
  }



}