import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { toQueryString } from "../reusable-functions/reusable.functions";
import { Externals } from "./GeneralConfig/externals.service";
import {
  handleError,
  handleErrorErrorResponse,
} from "./HttpErrorsHandler/HttpServiceErrorHandler.service";
import {
  CoachModel,
  CoachDetail,
  ReturnedCoachModel,
  returnedResult,
} from "./models/coache.model";
import { ErrorResponse, ErrorsTracker } from "./models/ErrorsTracker.model";
import {
  ApproveCoachModel,
  QueryStringModel,
} from "./models/query.string.model";
import { AppConfiguration } from "read-appsettings-json";
import {
  ExpertProfile,
  ProfilePagesCustom,
  _ExpertProfile,
  _ProfileMetaData,
} from "./models/ExpertProfile";

@Injectable()
export class CoachService {
  url = AppConfiguration.Setting().urls;
  httpHeaders = {
    headers: new HttpHeaders({
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }),
  };

  constructor(private _httpClientService: HttpClient) {}

  getCoaches(
    queryParameters: QueryStringModel
  ): Observable<CoachModel | ErrorResponse> {
    return this._httpClientService
      .post<CoachModel>(
        `${this.url?.adminUrl}/Coach/Coaches?${toQueryString(queryParameters)}`,
        null,
        this.httpHeaders
      )
      .pipe(
        tap((data) => {
          console.log(data);
        })

        // catchError(handleErrorErrorResponse)
        //  (err => {

        //  })
      );
  }

  getExperts(
    queryParameters: QueryStringModel
  ): Observable<ProfilePagesCustom[]> {
    return this._httpClientService
      .post<Array<ExpertProfile>>(
        `${this.url?.authUrl}/v1/register/get-all-registered-expert`,
        null,
        this.httpHeaders
      )
      .pipe(
        map((result) => {
          let list: ProfilePagesCustom[] = [];
          result.forEach((x) => {
            list.push(
              new ProfilePagesCustom(
                x.profilePages,
                x.currentPageNumber,
                x.currentPageSize,
                x.totalRecord,
                x.userId
              )
            );
          });
          return list;
        }),
        tap((data) => {
          console.log("Refined data ==> ", data);
        })
      );
  }
  getExpertWithNairaAccount() {
    return this._httpClientService
      .get(
        `${this.url?.learnersUrl}/v1/expert/admin?currency=NGN`,
        this.httpHeaders
      )
      .toPromise();
  }
  getExpertWithForeignAccount() {
    return this._httpClientService
      .get(`${this.url?.learnersUrl}/v1/expert/admin`, this.httpHeaders)
      .toPromise();
  }
  //_ProfileMetaData
  //data: _ProfileMetaData;
  _getExperts(
    queryParameters: QueryStringModel,
    search?: any
  ): Observable<_ExpertProfile> {
    return this._httpClientService
      .post<_ExpertProfile>(
        `${this.url?.authUrl}/v1-5/register/get-all-registered-expert${
          search ? `?search=${search}` : ""
        }`,
        null,
        this.httpHeaders
      )
      .pipe(
        map((result) => {
          return result;
        }),
        tap((data) => {
          //console.log(" xxx Refined data ==> ", data)
        })
      );
  }
  getExpertData(expertId: string) {
    return this._httpClientService
      .post(
        `${this.url?.authUrl}/v1/register/get-registered-expert?ExpertId=${expertId}`,
        {}
      )
      .toPromise();
  }
  getExpertDetail(id: string): Observable<ProfilePagesCustom | ErrorsTracker> {
    return this._httpClientService
      .post<ExpertProfile>(
        `${this.url?.authUrl}/v1/register/retrieve-profile-configuration`,
        { userId: id },
        this.httpHeaders
      )
      .pipe(
        map((result) => {
          let userProfile: ProfilePagesCustom;

          userProfile = new ProfilePagesCustom(
            result.profilePages,
            result.currentPageNumber,
            result.currentPageSize,
            result.totalRecord,
            result.userId
          );

          return userProfile;
        })

        // catchError(handleError)
      );
  }

  getCoachDetail(id: number): Observable<CoachDetail | ErrorsTracker> {
    return this._httpClientService
      .post<CoachDetail>(
        `${this.url.adminUrl}/Coach/CoachDetail?${toQueryString({ Id: id })}`,
        null,
        this.httpHeaders
      )
      .pipe(
        tap((data) => {
          console.log(data);
        })
        // catchError(handleError)
      );
  }

  approveCoach(
    approvedCoach: ApproveCoachModel
  ): Observable<returnedResult | ErrorsTracker> {
    console.log(
      "request sent to server",
      `${this.url.adminUrl}/Coach/ApproveCoach?${toQueryString(approvedCoach)}`
    );
    return this._httpClientService
      .post<returnedResult>(
        `${this.url.adminUrl}/Coach/ApproveCoach?${toQueryString(
          approvedCoach
        )}`,
        null,
        this.httpHeaders
      )
      .pipe(
        tap((data) => {
          console.log(data);
        })
        //catchError(handleError)
      );
  }
}
