import { PaginationModel } from "../services/models/pagination.model";
import { SessionsModel } from "../services/models/sessions.model";

export function toQueryString(obj){
  let parts= [];

    // tslint:disable-next-line: forin
    for (let property in obj){
      let value = obj[property];

      // tslint:disable-next-line: triple-equals
      if(value != null && value != undefined){
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
      }
    }

    console.log('returned joined path', parts.join('&'))
    return parts.join('&');
}

export function  returnedServerSidePaginationInitializer(pagination: any):PaginationModel{
 return new PaginationModel(
    pagination.currentPageCount,
    pagination.currentPageSize,
    pagination.totalRecord
  );
}