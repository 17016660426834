import { ServiceDomainCategory, _ServiceDomainCategory } from './../../../core/services/models/service.doamin.category.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Component, OnInit} from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import noUiSlider from "nouislider";
import Dropzone from "dropzone";
Dropzone.autoDiscover = false;
import Quill from "quill";
import Selectr from "mobius1-selectr";

@Component({
  selector: 'app-service-domain-category',
  templateUrl: 'service.category.domain.html',
  styleUrls: ['./service.category.domain.css']
})
export class ServiceCategoryDomainModal implements OnInit{
  private base64textString:String="";
  base64: string = "Base64...";
  imageUrl?: string;
  fileSelected?: File;
  form:FormGroup;
  focus;
  localCategoryDomain: _ServiceDomainCategory

  constructor(public _modal: NgbActiveModal,private sant: DomSanitizer){}

  ngOnInit(): void{
    this.form = new FormGroup({
      'name':new FormControl('',[Validators.required]),
      'description': new FormControl('',[Validators.required])
    })

   

    

     
  }

  get imageView(){
   
    return  this.imageUrl;
  }


  get name(){
    return this.form.get('name');
  }

  get description(){
    return this.form.get('description');
  }

  
  dismiss(){
   this._modal.dismiss();
  }

  

  private generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  files: File[] = [];

	onSelect(event) {
	
		this.files.push(...event.addedFiles);

    this.fileSelected=  this.files[0]
    this.imageUrl = this.sant.bypassSecurityTrustUrl(window.URL.createObjectURL(this.fileSelected)) as string;
    this.base64 = "Base64...";
	}

	onRemove(event) {
		console.log(event);
		this.files.splice(this.files.indexOf(event), 1);
	}

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString= btoa(binaryString) as string;
    //console.log(btoa(binaryString));
   }

   /**
   * Convert File To Base64
   */
  convertFileToBase64(): void {
    let reader = new FileReader();
    reader.readAsDataURL(this.files[0] as Blob);
    reader.onloadend = () => {
      this.base64 = reader.result as string;
      console.log("result from base 64",this.base64)
    }
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  onSubmit(){
   
      this.getBase64(this.files[0]).then(data =>{
        //console.log("base 64 data", data);
        let innerData = data;
        console.log("Inner data ==> ", innerData as string)
        
        this.localCategoryDomain = new _ServiceDomainCategory()
        this.localCategoryDomain.serviceDomainName = this.form.value.name;
        this.localCategoryDomain.description = this.form.value.description;
        this.localCategoryDomain.imageBase64String = data;

       console.log('domain known ==> ',this.localCategoryDomain)

        this._modal.close(this.localCategoryDomain);
  
      } )
      
      
     
    
      //this._modal.close(this.localCategoryDomain);
    }
}