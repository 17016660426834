import { DesignationService } from "./core/services/designation.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

import { TagInputModule } from "ngx-chips";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TabsModule } from "ngx-bootstrap/tabs";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { PresentationModule } from "./pages/presentation/presentation.module";

import { BrowserModule } from "@angular/platform-browser";
import { ComponentsModule } from "./components/components.module";

import { AppRoutingModule } from "./app-routing.module";
import { AuthService } from "./core/services/auth.service";
import { AuthGuard } from "./core/services/authgaurd.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { SharedModule } from "./shared/shared.module";
import { CountryService } from "./core/services/country.service";
import { ToastrModule } from "ngx-toastr";
import { StateService } from "./core/services/state.service";
import { OrganizationService } from "./core/services/organization.service";
import { InformationPageTextService } from "./core/services/information.page.text.service";
import { PricingPlanService } from "./core/services/pricing.plan.service";
import { PricingPlanDescriptionService } from "./core/services/pricing.plan.description.service";
import { ServiceDomainService } from "./core/services/service.doamin.service";
import { ServiceDomainCategoryService } from "./core/services/service.doamin.category.service";
import { PrivateSessionsService } from "./core/services/private.sessions.service";
import { CoachService } from "./core/services/coach.service";
import { EventsService } from "./core/services/events.service";
import { ProtegeesService } from "./core/services/protegees.service";
import { NgxYoutubePlayerModule } from "ngx-youtube-player";
import { NgxDropzoneModule } from "ngx-dropzone";
import { LoadingInterceptor } from "./core/interceptors/loading.interceptor";
import { CoreModule } from "./core/core.module";
import { ErrorInterceptor } from "./core/interceptors/error.interceptor";
import { VimeModule } from "@vime/angular";
import { PayoutService } from "./core/services/payout.service";
import { PayoutsComponent } from "./pages/payouts/payouts.component";

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    // PayoutsComponent,
  ],
  imports: [
    NgxSpinnerModule,
    BrowserAnimationsModule,

    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TabsModule.forRoot(),
    RouterModule,
    ComponentsModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    CollapseModule.forRoot(),
    TagInputModule,
    PresentationModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,

    CoreModule,
    VimeModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    CountryService,
    StateService,
    OrganizationService,
    DesignationService,
    InformationPageTextService,
    PricingPlanService,
    PricingPlanDescriptionService,
    ServiceDomainService,
    ServiceDomainCategoryService,
    PrivateSessionsService,
    CoachService,
    EventsService,
    ProtegeesService,
    PayoutService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
