export class PricingPlanDescription{
  id:number;
  pricing_plan_id: number;
  description_by_eachline: string;
  created_at: Date;
  updated_at: Date;
}

export class PricingPlanDescriptionVm{
  id:number;
  pricing_plan_id: number;
  pricing_plan_name: string;
  description_by_eachline: string;
  created_at: Date;
  updated_at: Date;
}