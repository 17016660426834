import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';

import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Country } from 'src/app/pages/settings/components/country/model/country.model';
import { CountryService } from 'src/app/core/services/country.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from 'src/app/core/services/alert.service';
import { ErrorsTracker } from 'src/app/core/services/models/ErrorsTracker.model';

@Component({
  selector: 'country-modal',
  templateUrl: './country.modal.html',
})
export class CountryModalComponent implements OnInit {
  focus;

  // initialize app user class
  country: Country = new Country();
  constructor(
    public activeModal: NgbActiveModal,
    private _countryService: CountryService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: AlertService
  ) {}

  form = new FormGroup({
    country_name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
  });

  get country_name() {
    return this.form.get('country_name');
  }

  ngOnInit() {}

  onSubmit() {
    this.spinner.show();
    this.country.name = this.form.value.country_name;
    
    this._countryService
      ._addCountry(this.country.name)
      .subscribe((ressult:number) => {
        this.toaster.successAlert("contry Added!");
        this.spinner.hide();
        this.activeModal.close(true);
      },
      (error: ErrorsTracker) =>{
        this.toaster.dangerAlert(error.friendlyMessage);
        this.spinner.hide();
        console.log('error in add country modal component : ', error)
      } );

   
  }

  private generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }
}
