import { City, CityV, _City, _City_State_Country } from './../../pages/settings/components/city/model/city.model';
import {Injectable} from "@angular/core";
import { StateService } from './state.service';
import { CountryService } from './country.service';
import { combineLatest, Observable, of } from 'rxjs';
import { AppConfiguration } from 'read-appsettings-json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorsTracker } from './models/ErrorsTracker.model';
import { catchError, map, tap } from 'rxjs/operators';
import { handleError, handleErrorAny } from './HttpErrorsHandler/HttpServiceErrorHandler.service';
import { toQueryString } from '../reusable-functions/reusable.functions';

@Injectable({
  providedIn: 'root'
})
export class CityService{

  url = AppConfiguration.Setting().urls;
  httpheader = {
    headers: new HttpHeaders({
      "content-type": "application/json",
    }),
  };


cities:City[] = [

  {
    id:233422,
    state_id: 1234571,
    country_id: 123456,
    city_name: 'Alakuko',
    timezone: 'West Africa Standard Time',
    created_at: new Date(),
    updated_at: new Date(),
  }
  ]
  constructor(private _httpService: HttpClient,private _stateService: StateService,
    private _countryService: CountryService){

  }

  _getCities(): Observable<_City[]|ErrorsTracker>{
    return this._httpService.get<_City[]>(`${this.url?.marketUrl}/COnfiguration/AllCities`,this.httpheader)
    .pipe(
      tap(data => {
        console.log(data)
      }),
      //catchError(handleError)
    );
  }


  
  _getCitiesReactively = this._httpService.get<_City[]>(`${this.url?.marketUrl}/COnfiguration/AllCities`,this.httpheader)
  .pipe(
    tap(data => {
      console.log(data)
    }),
    //catchError(handleErrorAny)
  );

  _addCity(city: _City): Observable<number | ErrorsTracker> {
    return this._httpService
      .post<number>(
        `${this.url?.marketUrl}/Configuration/AddCity?${toQueryString(city)}`,null,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
        //catchError(handleError)
      );
  }


  _updateCity(city: _City): Observable<number | ErrorsTracker> {
    return this._httpService
      .put<number>(
        `${this.url?.marketUrl}/Configuration/UpdateCity?${toQueryString(city)}`,null,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
        //catchError(handleError)
      );
  }


  __getState_City_Country$ = combineLatest([
    this._getCitiesReactively,
    this._stateService._getStateReactively,
    this._countryService._getCountriesReactively
    
  ]).pipe(
    map(([_cities,_states ,_countries])=>
    _cities.map(cities => ({
        id : cities.cityId,
        city_name : cities.cityName,
        country_id:cities.countryId,
        state_id : cities.stateId,
        country_name :_countries.find(x => x.id == cities.countryId)?.name,
        state_name: _states.find(x => x.stateId == cities.stateId)?.stateName,
        timezoneName: cities.timezoneName

      }) as _City_State_Country)
    ))


  addCity(data: City): Observable<boolean> {
    this.cities.push(data);
    return of(true);
  }


  private mapCityProperties(city: City): CityV{
    let cityV = new CityV()
    cityV.id = city.id;
    cityV.city_name = city.city_name;
    cityV.country_id = city.country_id
    cityV.country_name = this._countryService.countries.find(x => x.id == city.country_id).name;
    cityV.state_id = city.state_id;
    cityV.state_name = this._stateService.provinces.find(x => x.id == city.state_id).state_name;
    cityV.created_at = city.created_at;
    cityV.updated_at = city.updated_at;
    cityV.timezone = city.timezone;
    return cityV;
  }


  getAllCities(): Observable<CityV[]> {
    let localCities = this.cities.map(city => {
       return this.mapCityProperties(city);
    })
    return of(localCities);
  }

  isCityAlreadyExisting(data: City): Observable<boolean>{
    return of(this.isNameAlreadyExisting(data))
  }

  getCity(data:CityV): Observable<CityV>{
    var result = this.cities.find( x => x.id === data.id); 
    return of(this.mapCityProperties(result));
  }

  updateCity(data: CityV): Observable<boolean>{
    
    var isExist = this.isNameAlreadyExisting(data);

    if(isExist) return of(false);


    var result = this.cities.find( x => x.id === data.id);
    var getIndexPosition = this.cities.indexOf(result);
    result.country_id = data.country_id;
    result.state_id = data.state_id;
    result.country_id = data.country_id;
    result.updated_at = new Date();
    result.created_at = data.created_at;
    this.cities.splice(getIndexPosition,1,result)

    return of(true)
}

removeCity(data: CityV): Observable<boolean> {
    
  var returnedResult = this.removeItemFromPosition(data);
  return of(returnedResult);
}

private removeItemFromPosition(data: CityV): boolean{
   let i = 0;
   let length = this.cities.length;
   while(i < length){
     if(this.cities[i].id === data.id){
       this.cities.splice(i,1);
       return true
     }
     i++;
   }
   return false;
}
  private isNameAlreadyExisting(data: City): boolean{
    var result = this.cities.find( x => x.city_name.toLowerCase() === data.city_name.toLowerCase());
    if(result) return true;
  
    return false;
  }
}