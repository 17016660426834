import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProtegeeDetail } from 'src/app/core/services/models/protegee.model';
import { WalletHistoryModalComponent } from '../../modals/walletHistory/walletHistory.modal';

@Component({
  selector: 'app-protegees-wallet',
  templateUrl: './protegees-wallet.component.html',
  styleUrls: ['./protegees-wallet.component.scss']
})
export class ProtegeesWalletComponent implements OnInit {
  @Input() _protegee$:any;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

 
  getWalletHistory(){
    const modalRef = this.modalService.open(WalletHistoryModalComponent);
  }

}
