import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

// this helps to maintain spinner
@Injectable({
  providedIn:'root'
})
export class CustomSpinnerService{
  constructor(private spinner: NgxSpinnerService){

  }


  hideSpinnerFor1000MlSeconds(){
   setTimeout(()=> {
    this.spinner.hide();
   },1000)
  }

  showSpinner(){
    //show spinner
    this.spinner.show()
  }
}