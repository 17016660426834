import {Component, OnInit,Input} from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingPlan, _PricingPlan } from 'src/app/core/services/models/Pricing.plan.model'

@Component({
  selector: 'app-pricing-plan-edit',
  templateUrl: 'pricing.plan.edit.modal.html'
})
export class PricingPlanEditModal{

  @Input() public pricingList:_PricingPlan;
  focus;
  form: FormGroup;
  pricingPlan: _PricingPlan;
  constructor(public _modal: NgbActiveModal){}

  ngOnInit(){
    console.log('pricing list --> ',this.pricingList)
    this.form = new FormGroup({
      'id':new FormControl({value:this.pricingList.id,disabled:false},[Validators.required]),
      'plan_name': new FormControl({value:this.pricingList.name,disabled:false},[Validators.required]),
      'plan_brief_description': new FormControl({value:this.pricingList.briefSummary,disabled:false},[Validators.required]),
      // 'number_of_session': new FormControl({value:this.pricingList.,disabled:false},[Validators.required]),
      // 'number_of_session_permonth': new FormControl({value:this.pricingList.number_of_session_permonth,disabled:false},[Validators.required])
    })
  }


  get id(){
    return this.form.get('id');
  }

  get plan_name(){
    return this.form.get('plan_name');
  }
  get plan_brief_description(){
    return this.form.get('plan_brief_description');
  }

  get number_of_session(){
    return this.form.get('number_of_session');
  }

  get number_of_session_permonth(){
    return this.form.get('number_of_session_permonth')
  }

  onSubmit(){
    this.pricingPlan = new _PricingPlan();

    this.pricingPlan.id = this.form.value.id;
    this.pricingPlan.name = this.form.value.plan_name
    this.pricingPlan.briefSummary = this.form.value.plan_brief_description;
    

    console.log('edited pricing plan', this.pricingPlan)
    alert('pricing plan update yet to be implemented')
    this.dismiss();
    //this._modal.close(this.pricingPlan);

  }

  dismiss(){
    this._modal.dismiss();
  }
}