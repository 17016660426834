import { PreloadModulesStrategy } from "./core/strategies/preload-modules.strategy";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { PresentationComponent } from "./pages/presentation/presentation.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboards/alternative",
    pathMatch: "full",
  },
  {
    path: "presentation",
    component: PresentationComponent,
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "dashboards",
        loadChildren: "./pages/dashboards/dashboards.module#DashboardsModule",
      },

      {
        path: "examples",
        loadChildren: "./pages/examples/examples.module#ExamplesModule",
      },
      {
        path: "settings",
        loadChildren: "./pages/settings/settings.module#SettingsModule",
      },
      {
        path: "sessions",
        loadChildren: "./pages/sessions/sessions.module#SessionsModule",
      },
      {
        path: "events",
        loadChildren: "./pages/events/events.module#EventsModule",
      },
      {
        path: "subscribers",
        loadChildren: "./pages/protegees/protegees.module#ProtegeesModule",
      },
      {
        path: "experts",
        loadChildren: "./pages/coaches/coaches.module#CoachesModules",
      },
      {
        path: "management",
        loadChildren: "./pages/management/management.module#ManagementModules",
      },
      {
        path: "billings",
        loadChildren: "./pages/billings/billings.module#BillingsModule",
      },
      {
        path: "payouts",
        loadChildren: "./pages/payouts/payouts.module#PayoutsModule",
      },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "auth",
        loadChildren:
          "./layouts/auth-layout/auth-layout.module#AuthLayoutModule",
      },
    ],
  },
  {
    path: "**",
    redirectTo: "dashboard",
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadModulesStrategy,
      relativeLinkResolution: "legacy",
    }),
  ],
  providers: [PreloadModulesStrategy],
  exports: [RouterModule],
})
export class AppRoutingModule {}
