import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventModel } from 'src/app/core/services/models/event.model';

@Component({
  selector: 'app-coaches-events-modal',
  templateUrl: './coaches-events-detail-modal.component.html',
  styleUrls: ['./coaches-events-detail-modal.component.scss']
})
export class CoachesEventsModalComponent implements OnInit {

  @Input() public event;
  constructor(public _modalActive: NgbActiveModal) { }

  ngOnInit(): void {
    console.log("the result of modal event coach ->", this.event);
  }

  onRate($event){

  }

}
