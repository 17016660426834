import { Organization, _Organization } from './models/organization.model';
import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { AppConfiguration } from 'read-appsettings-json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorsTracker } from './models/ErrorsTracker.model';
import { catchError, tap } from 'rxjs/operators';
import { handleError } from './HttpErrorsHandler/HttpServiceErrorHandler.service';
import { toQueryString } from '../reusable-functions/reusable.functions';


@Injectable()
export class OrganizationService {

  
  url = AppConfiguration.Setting().urls;
  httpheader = {
    headers: new HttpHeaders({
      "content-type": "application/json",
    }),
  };

  
  Organizations: Organization[] =[
    {
      id: 12332,
      organization_name: 'Amazon',
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 12332,
      organization_name: 'FaceBook',
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 12333,
      organization_name: 'Google',
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 12334,
      organization_name: 'Dangote',
      created_at: new Date(),
      updated_at: new Date(),
    }
  ]

  constructor(private _httpService: HttpClient){}

  _getOrganizations(): Observable<_Organization[] | ErrorsTracker>{
    return this._httpService.get<_Organization[]>(`${this.url?.marketUrl}/Configuration/AllOrganizations`,this.httpheader)
    .pipe(
      tap(data => {
        console.log(data)
      }),
      catchError(handleError)
    );
  } 

  _addOrganization(name: string): Observable<number | ErrorsTracker> {
    return this._httpService
      .post<number>(
        `${this.url?.marketUrl}/Configuration/AddOrganization?${toQueryString({Name:name})}`,null,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
        catchError(handleError)
      );
  }

  
  _updateOrganization(ornaization: _Organization): Observable<number | ErrorsTracker> {
    return this._httpService
      .put<number>(
        `${this.url?.marketUrl}/Configuration/UpdateOrganization?${toQueryString(ornaization)}`,null,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
        catchError(handleError)
      );
  }
  addOrganization(data: Organization): Observable<boolean> {
    this.Organizations.push(data);
    return of(true);
  }


  private mapOrganizationProperties(organization: Organization): Organization{
    let organizationInstance = new Organization()
    organizationInstance.id = organization.id;
    organizationInstance.organization_name = organization.organization_name;
    organizationInstance.created_at = organization.created_at;
    organizationInstance.updated_at = organization.updated_at;
    return organizationInstance;
  }


  getOrganizations(): Observable<Organization[]> {
    
    return of(this.Organizations);
  }

  isOrganizationAlreadyExisting(data:Organization): Observable<boolean>{
    return of(this.isNameAlreadyExisting(data))
  }

  getOrganization(data:Organization): Observable<Organization>{
    var result = this.Organizations.find( x => x.id === data.id); 
    return of(this.mapOrganizationProperties(result));
  }

  updateOrganization(data: Organization): Observable<boolean>{
    
    var isExist = this.isNameAlreadyExisting(data);

    if(isExist) return of(false);


    var result = this.Organizations.find( x => x.id === data.id);
    var getIndexPosition = this.Organizations.indexOf(result);
    result.id = data.id;
    result.organization_name = data.organization_name;
    result.updated_at = new Date();
    result.created_at = data.created_at;
    this.Organizations.splice(getIndexPosition,1,result)

    return of(true)
}

removeOrganization(data: Organization): Observable<boolean> {
    
  var returnedResult = this.removeItemFromPosition(data);
  return of(returnedResult);
}

private removeItemFromPosition(data: Organization): boolean{
   let i = 0;
   let length = this.Organizations.length;
   while(i < length){
     if(this.Organizations[i].id === data.id){
       this.Organizations.splice(i,1);
       return true
     }
     i++;
   }
   return false;
}
  private isNameAlreadyExisting(data: Organization): boolean{
    var result = this.Organizations.find( x => x.organization_name.toLowerCase() === data.organization_name.toLowerCase());
    if(result) return true;
  
    return false;
  }
}