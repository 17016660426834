export interface ExpertProfile {
 userStepsTracker: Array<object>;
 profilePages: ProfilePages;
 isCompleted: boolean;
 currentPageNumber:number;
 currentPageSize:number;
 totalRecord: number;
 userId: string;
}

export interface _ExpertProfile {
    configs:Configs[];
    currentPageCount:number;
    totalRecord: number;
    currentPageSize:number;
   
   }

export class ProfilePagesCustom {
    constructor(public profilePages: ProfilePages,
        public currentPageNumber:number,
        public currentPageSize:number,
        public totalRecord: number,
        public userId: string
        ){}
    
  
   
}

export interface _ProfileMetaData {
   
    profilePages?: ProfilePages[];
    userId?: string;
    isCompleted?: boolean;
    isPublished?: boolean;
    currentPageCount?:number;
    totalRecord?: number;
    currentPageSize?:number;
   

  
   
}

export interface Configs {
    userStepsTracker: Array<object>;
    profilePages: ProfilePages;
    isCompleted: boolean;
    isPublished: boolean;
    currentPageNumber:number;
    userId: string;
    
    
}


export interface ProfilePages {
 registrationPage: RegistrationPage;
 professionalInformationPage: ProfessionalInformationPage;
 profileMediaPage: ProfileMediaPage;
 availabilityPage:AvailabilityPage;
 

}



export interface RegistrationPage {
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    password: string;
}

export interface ProfessionalInformationPage
{
    
    jobTitle:string;
    companyName: string;
    industryName: string;
    countryId: number;
    cityName: string;
    linkedInProfileUrl: string;
    professionalSummary:string;
    coachDomains:CoachDomain[];
}

export interface CoachDomain
{
    id: number;
    name: string;
}

export interface ProfileMediaPage
{
    profilePicture: string;
    profileVideoAttachedLink: string;
    profileVideoWrittenLink: string;
}

export interface AvailabilityPage
{
    hourlyRate: number;
    startDate: string;
    endDate: string;
    timeZone: string;
    alwaysAvailable: boolean;
    serviceScheduling: ServiceScheduling;
}

export interface ServiceScheduling
{
    sunday:DaysOfTheWeek;
    monday:DaysOfTheWeek;
    tuesday:DaysOfTheWeek;
    wednesday:DaysOfTheWeek;
    thursday:DaysOfTheWeek;
    friday:DaysOfTheWeek;
    saturday:DaysOfTheWeek;
}

export interface DaysOfTheWeek{
    active:boolean;
    hours:[];
}

