
export class Country {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}


export class _Country {
  id: number;
  name: string;
  
}