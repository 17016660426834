import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceDomainCategory, _ServiceDomainCategory } from 'src/app/core/services/models/service.doamin.category.model';
import { ServiceDomain, ServiceDomainVm, _ServiceDomain, _ServiceDomain_ServiceDomainCategory } from 'src/app/core/services/models/service.domain.model';
import { ServiceDomainCategoryService } from 'src/app/core/services/service.doamin.category.service';


@Component({
  selector:'app-service-domain-edit-modal',
  templateUrl: 'service.domain.edit.modal.html'
})
export class ServiceDomainEditModal implements OnInit{

  form:FormGroup;
  focus;
  localServiceDomain: _ServiceDomain
  localDomainCategoryList: _ServiceDomainCategory[]= [];
  @Input() public serviceDomainModel: _ServiceDomain_ServiceDomainCategory;
  
  constructor(public _modal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private _serviceDomainCategory: ServiceDomainCategoryService){
       // get domain category
    this.spinner.show();
    this._serviceDomainCategory._getserviceDomainCategory().subscribe((result:_ServiceDomainCategory[]) => {
      this.localDomainCategoryList = result;
      this.spinner.hide();
    })
   
    }
  


  ngOnInit(): void {
    this.form = new FormGroup({
      'id':new FormControl({value: this.serviceDomainModel.id, disabled:false},[Validators.required]),
      'service_domain_name':new FormControl({value: this.serviceDomainModel.name, disabled:false},[Validators.required]),
      'description': new FormControl({value: this.serviceDomainModel.description, disabled:false},[Validators.required]),
      'service_domain_category_id': new FormControl({value: this.serviceDomainModel.serviceDomainCategoryId, disabled:false},[Validators.required]),
      
    })
  }

  get created_at(){
    return this.form.get('created_at');
  }

  get id(){
    return this.form.get('id');
  }
  get service_domain_name(){
    return this.form.get('service_domain_name');
  }

  get description(){
    return this.form.get('description');
  }

  get service_domain_category_id(){
    return this.form.get('service_domain_category_id');
  }

  
  dismiss(){
   this._modal.dismiss();
  }


  onSubmit(){
    this.localServiceDomain = new _ServiceDomain()
    this.localServiceDomain.id =  this.form.value.id;
    this.localServiceDomain.name = this.form.value.service_domain_name;
    this.localServiceDomain.serviceDomainCategoryId = this.form.value.service_domain_category_id;
    this.localServiceDomain.description = this.form.value.description;
    
   
    this._modal.close(this.localServiceDomain);
  }

  getDomainCategory($event){

  }

  private generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  
}