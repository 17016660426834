import { CountryService } from './country.service';
import { Countries } from './../../pages/maps/vector/vector-map.service';
import {
  State,
  StateView,
  _State,
  _StateWithCountry,
} from './../../pages/settings/components/state/model/state.model';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { Country, _Country } from 'src/app/pages/settings/components/country/model/country.model';
import { AppConfiguration } from 'read-appsettings-json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { handleError, handleErrorAny } from './HttpErrorsHandler/HttpServiceErrorHandler.service';
import { ErrorsTracker } from './models/ErrorsTracker.model';
import { state } from '@angular/animations';
import { toQueryString } from '../reusable-functions/reusable.functions';
@Injectable()
export class StateService {
    countries:_Country[]=[];
    states: _State[] = [];
  url = AppConfiguration.Setting().urls;
  httpheader = {
    headers: new HttpHeaders({
      "content-type": "application/json",
    }),
  };


  stateView: StateView;
  localCountryService: _Country[];
  provincesView: StateView[] = [];
  provinces: State[] = [
    {
      id: 1234571,
      country_id: 123456,
      state_name: 'Lagos',
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 1234572,
      country_id: 123457,
      state_name: 'GhanaBrong Ahafo',
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 1234583,
      country_id: 123458,
      state_name: 'johannesburg',
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 1234594,
      country_id: 123459,
      state_name: 'Alexandria Governorate',
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 1234015,
      country_id: 123401,
      state_name: 'Adrar',
      created_at: new Date(),
      updated_at: new Date(),
    },
  ];

  constructor(private _countryService: CountryService,private _httpService: HttpClient) {
    this._countryService._getCountries().subscribe((x) => {
      this.localCountryService = x as _Country[];
    });
  }

  _getStates(): Observable<_State[]|ErrorsTracker>{
    return this._httpService.get<_State[]>(`${this.url?.marketUrl}/Configuration/AllStates`,this.httpheader)
    .pipe(
      tap(data => {
        console.log(data)
      }),
      //catchError(handleError)
    );
  }


  _addState(state: _State): Observable<number | ErrorsTracker> {
    return this._httpService
      .post<number>(
        `${this.url?.marketUrl}/Configuration/AddState?${toQueryString({StateName:state.stateName, CountryId:state.countryId})}`,null,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
       // catchError(handleError)
      );
  }


  _updateState(state: _State): Observable<number | ErrorsTracker> {
    console.log('-->',state)
    return this._httpService
      .put<number>(
        `${this.url?.marketUrl}/Configuration/UpdateState?${toQueryString({
          Id:state.stateId,
          countryId: state.countryId,
          stateName: state.stateName
        })}`,null,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
        //catchError(handleError)
      );
  }

  _getStateReactively = this._httpService.get<_State[]>(`${this.url?.marketUrl}/COnfiguration/AllStates`,this.httpheader)
  .pipe(
    tap(data => {
      console.log(data)
    }),
    //catchError(handleErrorAny)
  );

  __getStateWithCountry$ = combineLatest([
    this._getStateReactively,
    this._countryService._getCountriesReactively
    
  ]).pipe(
    map(([_states ,_countries])=>
      _states.map(state => ({
        id: state.stateId,
        stateName: state.stateName,
        countryId: state.countryId,
        countryName: _countries.find(x => x.id == state.countryId).name,

      }) as _StateWithCountry)
    ))


  addProvinceOrState(data: State): Observable<boolean> {
    this.provinces.push(data);
    return of(true);
  }


  getAllProvicesOrStates(): Observable<State[]> {
    return of(this.provinces);
  }

  isStateAlreadyExisting(data: State): Observable<boolean>{
    return of(this.isNameAlreadyExisting(data))
  }

  getState(data:State): Observable<State>{
    var result = this.provinces.find( x => x.id === data.id);
    return of(result);
  }

  updateState(data: State): Observable<boolean>{
    
    var isExist = this.isNameAlreadyExisting(data);

    if(isExist) return of(false);


    var result = this.provinces.find( x => x.id === data.id);
    var getIndexPosition = this.provinces.indexOf(result);

    result.state_name = data.state_name;
    result.country_id = data.country_id;
    result.updated_at = new Date();

    this.provinces.splice(getIndexPosition,1,result)

    return of(true)
}

removeState(data: State): Observable<boolean> {
    
  var returnedResult = this.removeItemFromPosition(data);
  return of(returnedResult);
}

private removeItemFromPosition(data: State): boolean{
   let i = 0;
   let length = this.provinces.length;
   while(i < length){
     if(this.provinces[i].id === data.id){
       this.provinces.splice(i,1);
       return true
     }
     i++;
   }
   return false;
}
  private isNameAlreadyExisting(data: State): boolean{
    var result = this.provinces.find( x => x.state_name.toLowerCase() === data.state_name.toLowerCase());
    if(result) return true;
  
    return false;
  }
}
