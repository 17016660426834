import { Component } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { NgSelectConfig } from "@ng-select/ng-select";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {

  constructor(private router: Router,private config: NgSelectConfig) {

     this.router.events.subscribe((event: Event) => {
         if (event instanceof NavigationStart) {
             // Show loading indicator
             window.scrollTo(0,0);
         }

         if (event instanceof NavigationEnd) {
             // Hide loading indicator
         }

         if (event instanceof NavigationError) {
             // Hide loading indicator

             // Present error to user
             console.log(event.error);
         }
     });

    //  this.config.notFoundText = 'Custom not found';
    //   this.config.appendTo = 'body';
    //   // set the bindValue to global config when you use the same 
    //   // bindValue in most of the place. 
    //   // You can also override bindValue for the specified template 
    //   // by defining `bindValue` as property
    //   // Eg : <ng-select bindValue="some-new-value"></ng-select>
    //   this.config.bindValue = 'value';
   }
}
