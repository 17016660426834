import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from "@angular/core";
import { Organization, _Organization } from "src/app/core/services/models/organization.model";

@Component({
  selector: 'app-edit-organization',
  templateUrl: 'edit.organization.modal.html'
})
export class EditOrganizationModal implements OnInit{

  focus;
  form: FormGroup;
  localOrganization: _Organization;
  @Input() public organization: _Organization;

  constructor(private _modal:NgbActiveModal){}

  ngOnInit(): void{

    console.log('Organization returned -->', this.organization)
    this.form = new FormGroup({
      'organization_name': new FormControl({value: this.organization.name,disabled:false},[Validators.required, Validators.min(3)])
    })
  }

  get organization_name(){
    return this.form.get('organization_name')
  }
  onSubmit(){
    this.localOrganization = new _Organization();

    this.localOrganization.id = this.organization.id;
    this.localOrganization.name = this.form.value.organization_name;
    

    this._modal.close(this.localOrganization);
    
  }

  dismiss(){
    this._modal.dismiss('Cross click');
  }
}