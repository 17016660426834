export class ErrorsTracker{

  constructor(public _errorNumber: number, public _message: any, public _friendlyMessage: string ){
    this.errorNumber = _errorNumber;
    this.message = _message;
    this.friendlyMessage = _friendlyMessage
  }
  public errorNumber: number;
  public message: any;
  public friendlyMessage: string;
}

export class ErrorsTrackerForAdminRegistration{

  constructor(public _errorNumber: number, public _message: [], public _friendlyMessage: string ){
    this.errorNumber = _errorNumber;
    this.message = _message;
    this.friendlyMessage = _friendlyMessage
  }
  public errorNumber: number;
  public message:[];
  public friendlyMessage: string;
}

export class ErrorResponse{

  constructor( 
    public error: any, 
    public message: string,
    public name: string, 
    public ok: boolean,
    public status: number,
    public url:string){
    
  }
  
 
}