import { Designation, _Designation } from './../../../core/services/models/designation.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-designation-modal',
  templateUrl: 'designation.modal.html'
})
export class DesignationModal implements OnInit{

  focus;
  localDesignation: _Designation;
  form: FormGroup
  constructor(public _modalService: NgbActiveModal){}



  ngOnInit(): void {

    this.form = new FormGroup({
      
      'designation_name': new FormControl('', [Validators.required, Validators.min(3)]),
      
    })

  }

  get designation_name(){
    return this.form.get('designation_name');
  }

  dismiss(){
    this._modalService.dismiss();
  }

  onSubmit(){
    this.localDesignation= new _Designation();
    
    this.localDesignation.name = this.form.value.designation_name;
   

    this._modalService.close(this.localDesignation);
  }

  private generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

}