export class Organization{
  id: number ;
  organization_name: string;
  created_at: Date;
  updated_at: Date;

}


export class _Organization{
  id: number ;
  name: string;

}