import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-session-payment-plan-badge',
  templateUrl: './custom-session-payment-plan-badge.component.html',
  styleUrls: ['./custom-session-payment-plan-badge.component.scss']
})
export class CustomSessionPaymentPlanBadgeComponent implements OnInit {
  @Input() public statusInValue: string;
  constructor() { }

  ngOnInit(): void {
  }

}
