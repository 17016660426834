import { EnsureModuleLoadedOnceGuard } from './esure-modules-loaded-once.guard';
import { CommonModule } from "@angular/common";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { ApprovedTransformedPipe } from './decorators/approved-transformed.pipe';
import { NotFoundComponent } from './not-found/not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';

@NgModule({
  declarations: [ApprovedTransformedPipe, NotFoundComponent, ServerErrorComponent],
  imports:[
    CommonModule
  ],
  exports:[ApprovedTransformedPipe]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard{
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
 constructor(@Optional() @SkipSelf() parentModule: CoreModule){
   super(parentModule);
 }
}