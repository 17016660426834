import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Externals } from "./GeneralConfig/externals.service";
import { ErrorsTracker } from "./models/ErrorsTracker.model";
import { EventModel } from "./models/event.model";
import { QueryStringModel } from "./models/query.string.model";
import { catchError, tap } from 'rxjs/operators';
import { handleError } from './HttpErrorsHandler/HttpServiceErrorHandler.service';
import { toQueryString } from '../reusable-functions/reusable.functions';

@Injectable()
export class EventsService{
  url: String = Externals.ApiService;
  httpHeaders = {
    headers: new HttpHeaders({
      'content-type':'application/json',
      'Access-Control-Allow-Origin':'*',
    })
  }

  constructor(private _httpClientService: HttpClient){}
  

   getEvents(queryParameters: QueryStringModel):Observable<EventModel| ErrorsTracker>{
    return this._httpClientService.post<EventModel>(`${this.url}/Event/Events?${toQueryString(queryParameters)}`,null,this.httpHeaders)
    .pipe(
      tap(data => {
        console.log(data)
      }),
     // catchError(handleError)
    );
  }
}