import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { State, _State } from './../../../pages/settings/components/state/model/state.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from "@angular/core";
import { CountryService } from 'src/app/core/services/country.service';
import { Country, _Country } from 'src/app/pages/settings/components/country/model/country.model';
import { StateService } from 'src/app/core/services/state.service';
import { ErrorsTracker } from 'src/app/core/services/models/ErrorsTracker.model';
import { AlertService } from 'src/app/core/services/alert.service';



@Component({
  selector:"app-state-modal",
  templateUrl:"state.modal.html",
})
export class StateModal implements OnInit{
  focus;
  form: FormGroup;
  stateValue: _State = new _State();
  countryList: _Country[] = [];
  constructor(private _modal: NgbActiveModal, 
    private _countryService: CountryService,
    private _alert: AlertService, 
    private _stateService: StateService,
    private _route: Router,
    private spinner: NgxSpinnerService){
    this.form = new FormGroup({
      id:new FormControl(""),
      country_id: new FormControl('',[Validators.required]),
      state_name: new FormControl('',[Validators.required,Validators.minLength(3)]),
      
  
  
    })
  }

  ngOnInit(){
    this.spinner.show();
    this._countryService._getCountries().subscribe((data:_Country[]) => {
      console.log('returned data' , data);
      this.countryList = data;
      this.spinner.hide();
    }, (error: ErrorsTracker) => {
      this._alert.dangerAlert(error.friendlyMessage);
      this.spinner.hide();
      console.log('error from state component : ', error);
    })
  }

  dismiss(){
    this._modal.dismiss();
  }

  
 

  get updated_at(){
    return this.form.get('updated_at');
  }
  get created_at(){
    return this.form.get('created_at')
  }
  get state_name(){
    return this.form.get('state_name');
  } 

  get country_id(){
    return this.form.get('country_id')
  }

  get id(){
    return this.form.get('id');
  }

  close(){
    
    this._modal.close();
  }

  onSubmit(){
    
    this.stateValue.countryId = +this.country_id.value;
    this.stateValue.stateName = this.state_name.value;

    this._modal.close(this.stateValue);
  }


  private generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }
}