import { Pipe, PipeTransform } from '@angular/core';
import { ReturenedIvitationViewResponse } from '../services/models/invitationResponse.model';

@Pipe({
  name: 'approvedTransformed'
})
export class ApprovedTransformedPipe implements PipeTransform {

  transform(value:  ReturenedIvitationViewResponse[], ...args: unknown[]): unknown {
    let _transform:any[] = []
    let result = value.map(invitation => {
      
       _transform.push(invitation.isApprovedByAdmin === true)
    });

    console.log("the filterd ==> ", _transform)

    return result;
  }

}
