import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Organization, _Organization } from './../../../core/services/models/organization.model';
import { FormControl, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-organization-modal',
  templateUrl: 'organization.modal.html'
})
export class OrganizationModal implements OnInit{

  focus;
  localOrganization: _Organization;
  form: FormGroup
  constructor(private _modalService: NgbActiveModal){}



  ngOnInit(): void {

    this.form = new FormGroup({
      
      'organization_name': new FormControl('', [Validators.required, Validators.min(3)]),
      
    })

  }

  get organization_name(){
    return this.form.get('organization_name');
  }

  dismiss(){
    this._modalService.dismiss();
  }

  onSubmit(){
    this.localOrganization = new _Organization();
    this.localOrganization.name = this.form.value.organization_name;
    
    this._modalService.close(this.localOrganization);
  }

  private generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

}