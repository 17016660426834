import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfiguration } from "read-appsettings-json";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Service } from "src/app/components/vector-map/vector-map.service";
import { toQueryString } from "../reusable-functions/reusable.functions";
import { Externals } from "./GeneralConfig/externals.service";
import { handleError, handleErrorAny } from "./HttpErrorsHandler/HttpServiceErrorHandler.service";
import { ErrorsTracker } from "./models/ErrorsTracker.model";
import { ServiceDomainCategory, _ServiceDomainCategory } from "./models/service.doamin.category.model";
import { ServiceDomain } from "./models/service.domain.model";
@Injectable()
export class ServiceDomainCategoryService{

  
  url = AppConfiguration.Setting().urls;
  httpHeaders = {
    headers: new HttpHeaders({
      'content-type':'application/json'
    })
  }
  constructor(private _httpClientService: HttpClient){}



  servicesDomain: ServiceDomainCategory[]=[
    {
    id:334552,
    name: 'Accounting',
    description: 'Best Accounting coaches available',
    created_at: new Date(),
    updated_at: new Date()

  },
  {
    id:334592,
    name: 'Public Administration',
    description: 'Be the crowd attention',
    created_at: new Date(),
    updated_at: new Date()

  },
  {
    id:334572,
    name: 'Computer Science',
    description: 'Be good at what you write in code',
    created_at: new Date(),
    updated_at: new Date()

  }
]

_getserviceDomainCategory(): Observable<_ServiceDomainCategory[]| ErrorsTracker>{
  return this._httpClientService.get<_ServiceDomainCategory[]>(`${this.url?.marketUrl}/Configuration/AllServiceDomainCategories`,this.httpHeaders)
  .pipe(
    tap(data => {
      console.log(data)
    }),
    //catchError(handleError)
  );
}


_getserviceDomainCategory$ = 
  this._httpClientService.get<_ServiceDomainCategory[]>(`${this.url?.marketUrl}/Configuration/AllServiceDomainCategories`,this.httpHeaders)
  .pipe(
    tap(data => {
      console.log(data)
    }),
    //catchError(handleErrorAny)
  );

 


getRedisCategoryService(name: string):Observable<string[]| ErrorsTracker>{
  return this._httpClientService.get<string[]>(`${this.url?.adminUrl}/Redis/GetAllRedisServiceCategory?name=${name}`,this.httpHeaders)
  .pipe(
    tap(data => {
      console.log(data)
    }),
    //catchError(handleError)
  );
}

_addServiceDomainCategory(serviceDomain: _ServiceDomainCategory){
  
 
  return this._httpClientService
    .post<number>(
      `${this.url?.marketUrl}/Configuration/AddServiceDomainCategory`,
      {ServiceDomainName:serviceDomain.serviceDomainName,
      Description:serviceDomain.description,
      ImageBase64String: serviceDomain.imageBase64String},
      this.httpHeaders
    )
    .pipe(
      tap((data) => {
        console.log(data);
      }),
      //catchError(handleError)
    );
}

_updateServiceDomainCategory(serviceDomain: _ServiceDomainCategory){
  console.log('service category -->', serviceDomain)
  return this._httpClientService
    .put<number>(
      `${this.url?.marketUrl}/Configuration/UpdateServiceDomainCategory`,
      {
      Id:serviceDomain.id,  
      ServiceDomainName:serviceDomain.serviceDomainName,
      Description:serviceDomain.description,
      ImageBase64String: serviceDomain.imageBase64String},
      this.httpHeaders
    )
    .pipe(
      tap((data) => {
        console.log(data);
      }),
     // catchError(handleError)
    );
}

  serviceDomainNameAlreadyExist(data:ServiceDomainCategory):Observable<boolean>{
    let result = this._getServiceDomainCategories.find(x => x.name.toLowerCase() == data.name.toLowerCase());

    if(result){
      return of(true);
    }

    return of(false);
  }
  get _getServiceDomainCategories():ServiceDomainCategory[]{
    return this.servicesDomain;
  }
  getServiceDomainCategories(): Observable<ServiceDomainCategory[]>{
    return of(this.servicesDomain)
  }

  MapDomainCategory(data:ServiceDomainCategory){
   let localDomainCategory: ServiceDomainCategory = new ServiceDomainCategory();
   localDomainCategory.id = data.id;
   localDomainCategory.created_at = data.created_at;
   localDomainCategory.updated_at = new Date();
   localDomainCategory.name = data.name;
   localDomainCategory.description = data.description;

   return localDomainCategory;
  }


  UpdateServiceDomainCategory(data: ServiceDomainCategory):Observable<boolean>{
    let indexToFind = this._getServiceDomainCategories.findIndex(x => x.id == data.id);
    let dataMapped = this.MapDomainCategory(data);
    this._getServiceDomainCategories.splice(indexToFind,1,dataMapped);   
    return of(true);
  }

  
  AddServiceDomainCategory(data: ServiceDomainCategory):Observable<boolean>{
    this.servicesDomain.push(data);
    return of(true);
  }

  getServiceDomainCategory(data: ServiceDomainCategory):Observable<ServiceDomainCategory>{
    return of(this.servicesDomain.find(x => x.id == data.id))
  }

  deleteServiceDomainCategory(data:  ServiceDomainCategory):Observable<boolean>{
    var dataIndex = this.servicesDomain.findIndex(x => x.id == data.id);
    this.servicesDomain.splice(dataIndex,1);

    return of(true);

  }
}