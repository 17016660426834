import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Component, OnInit} from '@angular/core'
import { PricingPlan, _PricingPlan } from 'src/app/core/services/models/Pricing.plan.model';

@Component({
selector:'app-pricing-plan-modal',
templateUrl: 'pricing.plan.modal.html'
})
export class PricingPlanModal implements OnInit {

//   id: number;
//   plan_name: string;
//   plan_brief_description: string;
//   number_of_session: number;
//   number_of_session_permonth: number;
//   created_at: Date;
//   updated_at: Date;
// }
  focus;
  form: FormGroup;
  pricingPlan: _PricingPlan
  constructor(public _modal: NgbActiveModal){}



  ngOnInit(){
    this.form = new FormGroup({
      'plan_name': new FormControl('',[Validators.required]),
      'plan_brief_description': new FormControl('',[Validators.required]),
      'number_of_session': new FormControl('',[Validators.required]),
      'number_of_session_permonth': new FormControl('',[Validators.required])
    })
  }

  get plan_name(){
    return this.form.get('plan_name');
  }
  get plan_brief_description(){
    return this.form.get('plan_brief_description');
  }

  get number_of_session(){
    return this.form.get('number_of_session');
  }

  get number_of_session_permonth(){
    return this.form.get('number_of_session_permonth')
  }

  onSubmit(){
    this.pricingPlan = new _PricingPlan();
    this.pricingPlan.id = this.generateRandomSixDigitNumber();
    this.pricingPlan.name = this.form.value.plan_name
    this.pricingPlan.descriptions = this.form.value.number_of_session;
    

    console.log('pricing plan logged' , this.pricingPlan);
     this._modal.close(this.pricingPlan)
    
  }

  dismiss(){
    this._modal.dismiss()
  }



  private generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

}