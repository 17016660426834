import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-session-status-badge',
  templateUrl: './custom-session-status-badge.component.html',
  styleUrls: ['./custom-session-status-badge.component.scss']
})
export class CustomSessionStatusBadgeComponent implements OnInit {

  @Input() public statusInText: string;

 
  constructor() { }

  ngOnInit(): void {
  }

}
