export class PricingPlan {
  id: number;
  plan_name: string;
  plan_brief_description: string;
  number_of_session: number;
  number_of_session_permonth: number;
  created_at: Date;
  updated_at: Date;
}

export class _PricingPlan {
  id: number;
  name: string;
  briefSummary: string;
  descriptions: _Description[];
}

export class _Description {
  id: number;
  description: string;
}