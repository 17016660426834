export class AppUserAuth {
  userName: string = "";
  password: string = "";
  bearerToken: string = "";
  isAuthenticated: boolean = false;
  isAnAdmin: boolean = false;
}

export const LOGIN_MOCKS: AppUserAuth[] = [
  {
    userName: "tobinoda@gmail.com",
    password: "password@coachmie",
    bearerToken: "ab244jdjndkkankzijknfdnjfjf",
    isAuthenticated: true,

    isAnAdmin: true,
  },
  {
    userName: "coachmie@gmail.com",
    password: "coachmiepassword",
    bearerToken: "ab244jdjndkkankzijknfdnjfjf",
    isAuthenticated: true,

    isAnAdmin: true,
  },
  {
    userName: "lola@yahoo.com",
    password: "password@coachmieloal_",
    bearerToken: "ab244jdjndkkankzijknfdnbne",
    isAuthenticated: true,

    isAnAdmin: false,
  },
];
