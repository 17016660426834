import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor{

    constructor(private router: Router,   private toastr: ToastrService){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
        .pipe(
            catchError(error => {
                if(error) {

                    if(error.status == 0){
                        this.toastr.error("Cannot connect to the server!", error.status)
                    }
                    if(error.status === 400){
                        if(error.error.errors){
                            //alert(JSON.stringify(error.error))
                            throw error.error;
                        }else{
                            this.toastr.error(error.error.message, error.error.statusCode)
                        }
                       
                    }
                    if(error.status === 401){
                        localStorage.clear()
                        this.toastr.error(error.error.message, error.error.statusCode)
                        window.location.href = "/auth/login"
                    }
                    if(error.status === 404){
                        //this.router.navigateByUrl('/not-found')
                        this.toastr.error("Page cannot be found", error.status)
                    }

                    if(error.status === 500){
                        //const navigationExtras: NavigationExtras = {state: {error: error.error}};
                        //this.router.navigateByUrl('/server-error',navigationExtras)
                        this.toastr.error("Internal server error", error.status)
                    }
                }
                return throwError(error);
            })
        )
    }
    
}