import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoachDetail } from 'src/app/core/services/models/coache.model';
import { CoachesEventsModalComponent } from '../../modals/coachesmodal/coaches-events-detail-modal.component';

@Component({
  selector: 'app-coaches-events',
  templateUrl: './coaches-events.component.html',
  styleUrls: ['./coaches-events.component.scss']
})
export class CoachesEventsComponent implements OnInit {

  @Input() _coach$:CoachDetail;
  constructor(private _modalService:NgbModal) { }

  ngOnInit(): void {
  }

  onRate($event){

  }

  viewEventDetail(event){
    console.log('Event cliecked upon', event)
    let modelReturned = this._modalService.open(CoachesEventsModalComponent,{ size: 'xl',centered: true});
      modelReturned.componentInstance.event = event;
  }

}
