import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-session-payment-status-badge',
  templateUrl: './custom-session-payment-status-badge.component.html',
  styleUrls: ['./custom-session-payment-status-badge.component.scss']
})
export class CustomSessionPaymentStatusBadgeComponent implements OnInit {

  @Input() public _statusInValue: string;
  constructor() { }

  ngOnInit(): void {
  }

}
