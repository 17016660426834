import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private toaster: ToastrService) {}

  defaultAlert(message) {
    return this.toaster.show(
      `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">Message</span> <span data-notify="message">${message.toUpperCase()}</span></div>`,
      '',
      {
        timeOut: 8000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-center',
        toastClass:
          'ngx-toastr alert alert-dismissible alert-default alert-notify',
      }
    );
  }

  dangerAlert(name) {
    return this.toaster.show(
      `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span>  <div class="alert-text"</div> <span class="alert-title" data-notify="title">Error!</span> <span data-notify="message">data name <b>${name.toUpperCase()}</b> exist</span>`,
      '',
      {
        timeOut: 8000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-right',
        toastClass: 'alert  alert-danger alert-notify',
      }
    );
  }

  successAlert(message) {
    return this.toaster.show(
      `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">Success!</span> <span data-notify="message">${message}</span></div>`,
      '',
      {
        timeOut: 8000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-right',
        toastClass:
          'alert alert-success alert-notify',
      }
    );
  }

  warningAlert(message) {
    return this.toaster.show(
      `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">Ngx Toastr</span> <span data-notify="message">${message}</span></div>`,
      '',
      {
        timeOut: 8000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-right',
        toastClass:
          'ngx-toastr alert alert-dismissible alert-warning alert-notify',
      }
    );
  }

  infoAlert(message) {
    return this.toaster.show(
      `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">Ngx Toastr</span> <span data-notify="message">${message}</span></div>`,
      '',
      {
        timeOut: 8000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-right',
        toastClass:
          'ngx-toastr alert alert-dismissible alert-info alert-notify',
      }
    );
  }
}
