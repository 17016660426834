import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoachDetail } from 'src/app/core/services/models/coache.model';
import { WalletHistoryModalComponent } from '../../modals/walletHistory/walletHistory.modal';

@Component({
  selector: 'app-coaches-wallet',
  templateUrl: './coaches-wallet.component.html',
  styleUrls: ['./coaches-wallet.component.scss']
})
export class CoachesWalletComponent implements OnInit {
  @Input() _coach$:CoachDetail;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  getWalletHistory(){
    const modalRef = this.modalService.open(WalletHistoryModalComponent);
  }
}
