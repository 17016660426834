import { PricingPlanDescription, PricingPlanDescriptionVm } from './models/pricing.plan.description.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PricingPlanService } from './pricing.plan.service';

@Injectable()
export class PricingPlanDescriptionService{

  constructor(private _pricingPlanService: PricingPlanService){

  }

  private pricingPlanDescriptionServices: PricingPlanDescription[]=[{
    id:562383,
    pricing_plan_id:567783,
    description_by_eachline: 'Hour-long one-on-one quick access to coaches',
    created_at: new Date(),
    updated_at: new Date()
  },
  {
    id:562383,
    pricing_plan_id:567783,
    description_by_eachline: 'Hour-long one-on-one quick access to coaches',
    created_at: new Date(),
    updated_at: new Date()
  },
  {
    id:562381,
    pricing_plan_id:567783,
    description_by_eachline: 'Configurable session hours',
    created_at: new Date(),
    updated_at: new Date()
  },
  {
    id:562363,
    pricing_plan_id:567783,
    description_by_eachline: 'Fast communication once session is booked',
    created_at: new Date(),
    updated_at: new Date()
  },
  {
    id:562363,
    pricing_plan_id:567727,
    description_by_eachline: 'Exclusive one-on-one access for 3 months',
    created_at: new Date(),
    updated_at: new Date()
  },
  {
    id:562363,
    pricing_plan_id:567727,
    description_by_eachline: 'Book 3 sessions per month for 3 months – 9 sessions in total',
    created_at: new Date(),
    updated_at: new Date()
  },
  {
    id:562363,
    pricing_plan_id:567727,
    description_by_eachline: 'Continuous communication with coaches throughout plan duration',
    created_at: new Date(),
    updated_at: new Date()
  }]


  privateGetMappedPricingPlanDescriptions(plans: PricingPlanDescription[])
  :PricingPlanDescriptionVm[]{
      return plans.map(result => {
        let newlyMappedPricingPlanDescription = new PricingPlanDescriptionVm();
        newlyMappedPricingPlanDescription.id = result.id;
        newlyMappedPricingPlanDescription.description_by_eachline = result.description_by_eachline;
        newlyMappedPricingPlanDescription.pricing_plan_id = result.pricing_plan_id;
        newlyMappedPricingPlanDescription.pricing_plan_name = this._pricingPlanService
        ._pricingPlanServices.find(x => x.id == result.pricing_plan_id).plan_name;
        newlyMappedPricingPlanDescription.created_at = new Date();
        newlyMappedPricingPlanDescription.updated_at = new Date();

        return newlyMappedPricingPlanDescription;

      })
  }


  privateGetMappedPricingPlanDescriptionVm(result: PricingPlanDescription)
  :PricingPlanDescriptionVm{

        let newlyMappedPricingPlanDescription = new PricingPlanDescriptionVm();
        newlyMappedPricingPlanDescription.id = result.id;
        newlyMappedPricingPlanDescription.description_by_eachline = result.description_by_eachline;
        newlyMappedPricingPlanDescription.pricing_plan_id = result.pricing_plan_id;
        newlyMappedPricingPlanDescription.pricing_plan_name = this._pricingPlanService
        ._pricingPlanServices.find(x => x.id == result.pricing_plan_id).plan_name;
        newlyMappedPricingPlanDescription.created_at = new Date();
        newlyMappedPricingPlanDescription.updated_at = new Date();

        return newlyMappedPricingPlanDescription;

      
  }


  privateGetMappedPricingPlanDescription(result: PricingPlanDescriptionVm)
  :PricingPlanDescription{

        let newlyMappedPricingPlanDescription = new PricingPlanDescription();
        newlyMappedPricingPlanDescription.id = result.id;
        newlyMappedPricingPlanDescription.description_by_eachline = result.description_by_eachline;
        newlyMappedPricingPlanDescription.pricing_plan_id = result.pricing_plan_id;
        newlyMappedPricingPlanDescription.created_at = new Date();
        newlyMappedPricingPlanDescription.updated_at = new Date();

        return newlyMappedPricingPlanDescription;

      
  }

  privateGetMappedPricingPlanDescriptionOnly(result: PricingPlanDescription)
  :PricingPlanDescription{

        let newlyMappedPricingPlanDescription = new PricingPlanDescription();
        newlyMappedPricingPlanDescription.id = result.id;
        newlyMappedPricingPlanDescription.description_by_eachline = result.description_by_eachline;
        newlyMappedPricingPlanDescription.pricing_plan_id = result.pricing_plan_id;
        newlyMappedPricingPlanDescription.created_at = result.created_at;
        newlyMappedPricingPlanDescription.updated_at = result.updated_at;

        return newlyMappedPricingPlanDescription;

      
  }


 

  UpdateDescriptionPlan(data):Observable<boolean>{
    let indexToFind = this._pricingPlanDescriptionServices.findIndex(x => x.id == data.id);
    let dataMapped = this.privateGetMappedPricingPlanDescriptionOnly(data);
    this.pricingPlanDescriptionServices.splice(indexToFind,1,dataMapped);   
    return of(true);
  }

  get _pricingPlanDescriptionServices():PricingPlanDescription[]{
    return this.pricingPlanDescriptionServices;
  }


  getPricingDescriptionPlans(): Observable<PricingPlanDescriptionVm[]>{
    return of(this.privateGetMappedPricingPlanDescriptions(this._pricingPlanDescriptionServices))
  }

  AddDescriptionPlan(data: PricingPlanDescription):Observable<boolean>{
    this.pricingPlanDescriptionServices.push(data);
    return of(true);
  }

  getDescriptionPlan(data: PricingPlanDescriptionVm):Observable<PricingPlanDescriptionVm>{
    return of(this.privateGetMappedPricingPlanDescriptionVm(data))
  }

 
  deleteDescriptionPlan(data:  PricingPlanDescription):Observable<boolean>{
    var dataIndex = this.pricingPlanDescriptionServices.findIndex(x => x.id == data.id);
    this.pricingPlanDescriptionServices.splice(dataIndex,1);

    return of(true);

  }
}