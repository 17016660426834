
export class PaginationModel{
  constructor(_currentPageCount:number =0,_currentPageSize:number = 0,_totalRecord = 0){
    this.currentPageCount = _currentPageCount;
    this.currentPageSize = _currentPageSize;

    this.totalRecord = _totalRecord;
  }
  currentPageCount: number;
  currentPageSize: number;
  totalRecord: number;

}
