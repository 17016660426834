export class City{
  id: number ;
  city_name: string;
  state_id: number;
  timezone: string;
  country_id: number;
  created_at: Date;
  updated_at: Date;

}

export class CityV{
  id: number ;
  city_name: string;
  state_id: number;
  state_name: string;
  timezone: string;
  country_id: number;
  country_name: string;
  created_at: Date;
  updated_at: Date;

}

export class _City{
  cityId: number ;
  cityName: string;
  stateId: number;
  countryId: number;
  timezoneName: string;
}

export class _City_State_Country{
  id: number ;
  country_id: number;
  state_id : number;
  city_name: string;
  state_name: string;
  country_name: string;
  timezoneName: string;
}

