import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-teachable-badge',
  templateUrl: './custom-teachable-badge.component.html',
  styleUrls: ['./custom-teachable-badge.component.scss']
})
export class CustomTeachableBadgeComponent implements OnInit {
  @Input() public statusInText: string;
  @Input() public value: string;
  constructor() { }

  ngOnInit(): void {
  }

}
