import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfiguration } from "read-appsettings-json";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { toQueryString } from "../reusable-functions/reusable.functions";
import { Externals } from "./GeneralConfig/externals.service";
import { handleError } from "./HttpErrorsHandler/HttpServiceErrorHandler.service";
import { ErrorsTracker } from "./models/ErrorsTracker.model";
import { ProtegeeDetail, ProtegeeModel } from "./models/protegee.model";
import { QueryStringModel } from "./models/query.string.model";


@Injectable()
export class ProtegeesService {
  url: String = Externals.ApiService;
  appUrls = AppConfiguration.Setting().urls;
  userData: any = localStorage.getItem("bearerToken") && JSON.parse(localStorage.getItem("bearerToken"));
  httpHeaders = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': "application/json",
      "x-auth-token": this.userData?.authToken || ""
    })
  }

  constructor(private _httpClientService: HttpClient) { }



  getProtegees(queryParameters: QueryStringModel): Observable<any | ErrorsTracker> {
    return this._httpClientService.get<ProtegeeModel>(`${this.appUrls?.learnersUrl}/v1/users?perPage=${queryParameters.pageSize}&pageNo=${queryParameters.pageNumber}`, this.httpHeaders)
      .pipe(
        tap(data => {
          console.log(data)
        }),
        //catchError(handleError)
      );
  }

  getProtegeeDetail(id: any): Observable<any | ErrorsTracker> {
    return this._httpClientService.get<ProtegeeDetail>(`${this.appUrls?.learnersUrl}/v1/user/${id}`, this.httpHeaders)
      .pipe(
        tap(data => {
          console.log(data)
        }),
        //catchError(handleError)
      );
  }

  getUserSttings(): any {
    return this._httpClientService.get<any>(`${this.appUrls?.learnersUrl}/v1/user/setting`, this.httpHeaders).toPromise()
  }
  addUserSttings(data:any): any {
    return this._httpClientService.post<any>(`${this.appUrls?.learnersUrl}/v1/user/setting`, data, this.httpHeaders).toPromise()
  }
}