import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import {
  Country,
  _Country,
} from "src/app/pages/settings/components/country/model/country.model";
import { AppConfiguration } from "read-appsettings-json";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import {
  handleError,
  handleErrorAny,
} from "./HttpErrorsHandler/HttpServiceErrorHandler.service";
import { ErrorsTracker } from "./models/ErrorsTracker.model";
import { toQueryString } from "../reusable-functions/reusable.functions";

@Injectable()
export class CountryService {
  url = AppConfiguration.Setting().urls;
  httpheader = {
    headers: new HttpHeaders({
      "content-type": "application/json",
    }),
  };

  constructor(private _httpService: HttpClient) {}

  countries: Country[] = [
    {
      id: 123456,
      name: "Nigeria",
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 123457,
      name: "Ghana",
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 123458,
      name: "South Africa",
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 123459,
      name: "Egypt",
      created_at: new Date(),
      updated_at: new Date(),
    },
    {
      id: 123401,
      name: "Algeria",
      created_at: new Date(),
      updated_at: new Date(),
    },
  ];

  _getCountriesReactively = this._httpService
    .get<_Country[]>(
      `${this.url?.marketUrl}/COnfiguration/AllCountries`,
      this.httpheader
    )
    .pipe(
      tap((data) => {
        console.log(data);
      }),
     // catchError(handleErrorAny)
    );

  _getCountries(): Observable<_Country[] | ErrorsTracker> {
    return this._httpService
      .get<_Country[]>(
        `${this.url?.marketUrl}/COnfiguration/AllCountries`,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
       // catchError(handleError)
      );
  }


  _addCountry(name: string): Observable<number | ErrorsTracker> {
    return this._httpService
      .post<number>(
        `${this.url?.marketUrl}/Configuration/AddCountry?${toQueryString({Name:name})}`,null,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
       // catchError(handleError)
      );
  }


  _updateCountry(country: _Country): Observable<number | ErrorsTracker> {
    return this._httpService
      .put<number>(
        `${this.url?.marketUrl}/Configuration/UpdateCountry?${toQueryString(country)}`,null,
        this.httpheader
      )
      .pipe(
        tap((data) => {
          console.log(data);
        }),
       // catchError(handleError)
      );
  }
  getCountries(): Observable<Country[]> {
    return of(this.countries);
  }

  addCountry(data: Country): Observable<boolean> {
    this.countries.push(data);
    return of(true);
  }

  updateCountry(data: Country): Observable<boolean> {
    var isExist = this.isNameAlreadyExisting(data);

    if (isExist) return of(false);

    var result = this.countries.find((x) => x.id === data.id);
    var getIndexPosition = this.countries.indexOf(result);

    result.name = data.name;

    this.countries.splice(getIndexPosition, 1, result);

    return of(true);
  }

  private isNameAlreadyExisting(data: Country): boolean {
    var result = this.countries.find(
      (x) => x.name.toLowerCase() === data.name.toLowerCase()
    );
    if (result) return true;

    return false;
  }

  isCountryAlreadyExisting(data: Country): Observable<boolean> {
    var result = this.countries.find(
      (x) => x.name.toLowerCase() === data.name.toLowerCase()
    );
    if (result) return of(true);

    return of(false);
  }

  getCountry(data: Country): Observable<Country> {
    var result = this.countries.find((x) => x.id === data.id);
    return of(result);
  }

  removeCountry(data: Country): Observable<boolean> {
    var returnedResult = this.removeItemFromPosition(data);
    return of(returnedResult);
  }

  private removeItemFromPosition(data: Country): boolean {
    let i = 0;
    let length = this.countries.length;
    while (i < length) {
      if (this.countries[i].id === data.id) {
        this.countries.splice(i, 1);
        return true;
      }
      i++;
    }
    return false;
  }
}
