import { MainWordValidator } from './custom-validators/mainword.validators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { InformationTextPage, _InformationTextPage } from 'src/app/core/services/models/Information.page.text.model';
import { AppSettingsService } from 'src/app/core/services/app.settings.service';
import { WordSettings } from 'src/app/core/services/models/app.settings.model';

 
@Component({
  selector: 'app-modal-information-page',
  templateUrl: 'information.page.modal.html'
})
export class ModalInformationPage implements OnInit{

  focus;
  minLength: number;
  isActiveResponse: boolean = false;
  localInformationText: InformationTextPage = new InformationTextPage();
  localInformationTextToBeMapped:  _InformationTextPage;
  localValidator: WordSettings = new WordSettings();

  constructor(public _modal: NgbActiveModal, private _AppSettingsService: AppSettingsService){
    
  }

  ngOnInit(): void{

    this._AppSettingsService.getWordSettingsSetting().subscribe(
      x => {
        this.localValidator = x;
        this.minLength = x.mainWord;
        console.log('returned from settings ', this.localValidator)
      }
    )
    
  }



  onSubmit(data){
    
    this.localInformationTextToBeMapped =  new _InformationTextPage();
    this.localInformationTextToBeMapped.landingPageMainword =this.localInformationText.landing_page_mainword;
    this.localInformationTextToBeMapped.landingPageMainwordSub = this.localInformationText.becomecoach_mainword_sub;
    this.localInformationTextToBeMapped.findcoachMainword = this.localInformationText.findcoach_mainword;
    this.localInformationTextToBeMapped.findcoachMainwordSub = this.localInformationText.findcoach_mainword_sub;
    this.localInformationTextToBeMapped.becomecoachMainword = this.localInformationText.becomecoach_mainword;
    this.localInformationTextToBeMapped.becomecoachMainwordSub = this.localInformationText.becomecoach_mainword_sub;
    this.localInformationTextToBeMapped.eventMainword = this.localInformationText.event_mainword;
    this.localInformationTextToBeMapped.eventMainwordSub = this.localInformationText.event_mainword_sub
    this.localInformationTextToBeMapped.taketourVideoUrl = this.localInformationText.taketour_video_url;
    this.localInformationTextToBeMapped.activeText =  this.localInformationText.active_text;
    this._modal.close(this.localInformationTextToBeMapped);
    this.isActiveResponse = !this.isActiveResponse;

    console.log('submitted  mapped data --> ',this.localInformationTextToBeMapped)
   // this.localInformationText = new InformationTextPage();

  }


  dismiss(){
    this._modal.dismiss();
  }

  isActiveChnaged($event){
    if($event.target.checked === true  || $event.target.checked === false){
      this.isActiveResponse = $event.target.checked
    }
   
  }
}